/* eslint-disable */

import React, { useState } from 'react'
import cn from 'classnames'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

import { css, StyleSheet } from 'aphrodite'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useLoad } from '../hooks/request'
import { BORROW_LIST, CLIENT_DETAIL } from '../urls'
import empty from '../static/empty-product.png'
import Table from './common/Table'
import ClientBorrowItem from './ClientBorrowItem'
import { useQueryParams } from '../hooks/queryString'
import BorrowOutCreate from './BorrowOutCreate'
import Button from './common/Button'
import { useCustomModal } from '../hooks/customModal'
import { measurement } from '../utils/position'
import BorrowInCreate from "./BorrowInCreate";

pdfMake.vfs = pdfFonts.pdfMake?.vfs

export default function ClientInfo({ item }) {
    const { clientId = item?.id } = useParams()
    const { params } = useQueryParams()
    const [page, setPage] = useState(1)
    const { response: client } = useLoad({ url: CLIENT_DETAIL.replace('{id}', clientId) })
    const borrows = useLoad({
        url: BORROW_LIST,
        params: { page, ...params, client_id: clientId, status: 'active' }
    }, [params, page])
    const allBorrowsData = useLoad({
        url: BORROW_LIST,
        params: { page, ...params, size: 100000, client_id: clientId, status: 'active' }
    }, [params, page])
    const allBorrows = allBorrowsData?.response?.results || []
    const borrowItem = borrows.response ? borrows.response.results : []
    const totalSum = borrowItem.reduce((prev, current) => prev + current.amountIn, 0)
    const totalUsd = borrowItem.reduce((prev, current) => prev + current.amountIn, 0)

    const [showCreateModal, hideCreateModal] = useCustomModal(<BorrowOutCreate borrows={borrows}
                                                                               clientId={clientId}
                                                                               onCancel={() => hideCreateModal()}/>, styles.modal)
    const [showPayModal, hidePayModal] = useCustomModal(<BorrowInCreate borrows={borrows}
                                                                        clientId={clientId}
                                                                        onCancel={() => hidePayModal()}/>, styles.modal)
    const formatNumber = (num) => {
        return new Intl.NumberFormat('ru-RU').format(num)
    }
    const exportToPdf = () => {
        const res = allBorrows?.map((item) => {
            const { selectOrder } = item
            const orderItems = selectOrder?.orderProduct || []

            const amount = formatNumber(item.amount)
            const currency = item.currency === 'sum' ? 'Сум' : 'USD'

            return [
                { text: 'Товары', style: 'sectionTitle' },
                {
                    table: {
                        headerRows: 1,
                        widths: [30, '*', 50, 50, 60, 50],
                        body: [
                            [
                                { text: '№', style: 'tableHeader' },
                                { text: 'Наименование', style: 'tableHeader' },
                                { text: 'Ед. изм', style: 'tableHeader' },
                                { text: 'Кол-во', style: 'tableHeader' },
                                { text: 'Цена', style: 'tableHeader' },
                                { text: 'Сумма', style: 'tableHeader' },
                            ],
                            ...orderItems.map((product, index) => [index + 1, product.product.name, measurement(product.product.measurement), product.count, formatNumber(product.price), formatNumber(product.price * product.count)]),
                        ],
                    },
                },
                { text: ' ' },
                {
                    columns: [
                        { text: 'Итого:', alignment: 'right', style: 'totalsLabel' },
                        { text: amount + ' ' + currency, alignment: 'right', style: 'totalsValue' },
                    ],
                },
            ]
        })

        const docDefinition = {
            content: [
                { text: 'СЧЕТ-ФАКТУРА', style: 'header' },
                { columns: [{ text: 'Поставщик' }, { text: 'SKLAT', bold: true }] },
                { columns: [{ text: 'Адрес' }, { text: 'SHOFIRKON', bold: true }] },
                { text: 'Покупатель', style: 'sectionTitle' },
                { columns: [{ text: `${client?.name} ${client?.phoneNumber}` }, { text: `Контакт: ${client?.phoneNumber}` }] },
                { text: ' ' },
                ...res.flat(),
            ],
            styles: {
                header: { fontSize: 18, bold: true, marginBottom: 10 },
                subheader: { fontSize: 12, marginBottom: 5 },
                sectionTitle: { fontSize: 14, bold: true, marginTop: 10, marginBottom: 5 },
                tableHeader: { bold: true, fontSize: 12, color: 'black' },
                totalsLabel: { bold: true, fontSize: 12, marginTop: 10 },
                totalsValue: { bold: true, fontSize: 12, marginTop: 10 },
            },
        }

        pdfMake.createPdf(docDefinition).download(`invoice_${client.id}.pdf`)
    }

    function format(value) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
            .format(value)
            .replace(/,/g, ' ')
    }

    return (
        <div>
            <header className="mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <hgroup style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <Link className="is-white is-link is-outlined" to="/borrow">
                        <i className="icon ion-md-arrow-back is-size-4"/>
                    </Link>
                    <span className="is-size-4 is-uppercase">
                        <b>
                            {client?.name} - {client?.phoneNumber}
                        </b>
                    </span>

                    <Button onClick={showPayModal} text="Оплатить "
                            className="is-link is-outlined"/>
                    <Button onClick={showCreateModal} text="Задолженность"
                            className="is-link is-outlined"/>
                </hgroup>


            </header>

            {borrows.response && borrows.response.count === 0 ? (
                <div>
                    <div className="is-flex is-justify-content-center is-align-items-center">
                        <h1>
                            <b>Не долг</b>
                        </h1>
                    </div>
                    <div className="is-flex is-justify-content-center is-align-items-center">
                        <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')}/>
                    </div>
                </div>
            ) : (
                <div className="">
                    <Table

                        loading={borrows.loading}
                        items={borrowItem}
                        columns={{
                            number: '№',
                            amountIn: 'Сумма долга',
                            amountOut: 'Оплачено',
                            name: 'Способ оплаты',
                            status: 'Статус',
                            registeredAt: 'Дата',
                        }}
                        totalCount={borrows.response ? borrows.response.count : 0}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item, index) => <ClientBorrowItem index={index} borrows={borrows}
                                                                       clientId={clientId} key={item.id} item={item}/>}
                    />


                </div>
            )}


        </div>
    )
}

const styles = StyleSheet.create({
    space: { marginTop: '2rem' },
    emptyImg: { width: '330px', height: '300px' },
    loader: { marginTop: '2rem' },
    modal: { width: 800 }
})
