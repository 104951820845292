import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { ORDER_DETAIL } from '../urls'
import { useDeleteRequest, usePutRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { format } from '../utils/number'
import CheckProductItem from './CheckProductItem'
import CheckView from './CheckView'
import ComponentToPrint from './Print'
import Button from './common/Button'
import CheckUpdateCustom from './CheckUpdateCustom'

export default function CheckItem({ reload, item, check, products }) {
    const checkRemove = useDeleteRequest({ url: ORDER_DETAIL.replace('{id}', item.id) })

    async function onDeleteCheck() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await checkRemove.request()
            await reload.request()
        }
    }

    // eslint-disable-next-line max-len,no-return-assign
    const result = item.orderProduct.reduce((total, currentValue) => total + parseInt(currentValue.price * currentValue.count, 10), 0)

    const [showCheckView, hideCheckView] = useModal(<CheckView check={check} items={item.orderProduct} client={item} itemId={item.id} order={item} onCancel={() => hideCheckView()} item={item.orderProduct} />)

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })
    const updateOrder = usePutRequest({ url: ORDER_DETAIL.replace('{id}', item.id) })
    async function onSubmit(data) {
        const { success } = await updateOrder.request({
            data: { status: 'archive', items: item.orderProduct, client: item.client.id, ...data },
        })
        if (success) {
            reload.request()
        }
    }

    return (
        <div className="box">
            <div style={{ marginTop: -23 }} className="is-flex is-align-items-center is-justify-content-center is-fullwidth">
                <div style={{ width: '100%' }}>
                    <CheckUpdateCustom reload={reload} item={item} />
                </div>
            </div>

            <div className="mt-3">
                <table className="table is-striped is-narrow is-hoverable is-fullwidth is-capitalized">
                    <thead>
                        <tr style={{ textAlign: 'center' }}>
                            <th className="is-size-6">Товара</th>
                            <th className="is-size-6">Количество</th>
                            <th className="is-size-6">Цена</th>
                            <th className="is-size-6">Итого</th>
                            <th>
                                <Button onClick={onDeleteCheck} className="button is-danger is-small" icon="trash" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {item.orderProduct.map((element) => (
                            <CheckProductItem key={element?.id} products={products} check={check} reload={reload} element={element} />
                        ))}
                        <tr>
                            <td colSpan={7}>
                                <b>
                                    <span className="is-flex is-justify-content-center">
                                        <p className="is-size-6 pt-2 pb-2">Общий: {format(result)} Сум</p>
                                    </span>
                                </b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <footer className="card-footer">
                <p onClick={showCheckView} className="card-footer-item is-bold">
                    <b>Долг</b>
                </p>
                <p onClick={() => onSubmit({ paymentMethod: 'cash' })} className="card-footer-item">
                    <b>Наличные</b>
                </p>
                <p onClick={() => onSubmit({ paymentMethod: 'terminal' })} className="card-footer-item">
                    <b>Терминал</b>
                </p>
                <p onClick={handlePrint} className="card-footer-item">
                    <b>Принт</b>
                </p>
                <div style={{ display: 'none' }}>
                    <ComponentToPrint result={result} item={item} ref={componentRef} />
                </div>
            </footer>
        </div>
    )
}
