import React from 'react'

export default function PriceTagsColumn({ item, index }) {
    return (
        <tr className=" is-size-6" key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td className="is-size-6">{item?.product_name}</td>
            <td className="is-size-6">{item?.quantity}</td>
            <td className="is-size-6">{item?.code}</td>
            <td className="is-size-6">{item?.price}</td>
            <td className="is-size-6">{item?.discount}</td>
            <td className="is-size-6">{item?.action}</td>
        </tr>
    )
}
