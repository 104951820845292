import React from 'react'

export default function SummaryCard({ label, value }) {
    return (
        <div className="card" style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', alignItems: 'center', padding: '1rem' }}>
            <b>{label}</b>
            <p className="is-size-6 tag is-light has-text-weight-semibold">{value}</p>
        </div>
    )
}
