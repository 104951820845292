import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { ORDER_LIST, ORDER_PRODUCT_LIST } from '../urls'
import Button from './common/Button'
import { measurement } from '../utils/position'
import { format } from '../utils/number'
import { useLoad, usePostRequest } from '../hooks/request'
import Select from './common/Select'

export default function ProductDetailModal({ item, check, hideModal, reload }) {
    const [count, setCount] = useState(1)
    const products = usePostRequest({ url: ORDER_PRODUCT_LIST })
    const orders = useLoad({ url: ORDER_LIST })
    const orderItem = orders.response ? orders.response.results : []
    const regionOptions = Object.entries(orderItem).map(([, {
        id,
        client,
    }]) => ({
        id,
        name: client?.name,
    }))
    async function onSubmit(data) {
        const {
            success,
        } = await products.request({
            data: {
                ...data,
                product: item.id,
                count,
                price: item.priceSale,
            },
        })
        if (success) {
            hideModal()
            check.request()
            reload.request()
        }
    }

    return (
        <Formik initialValues={{ product: item.id, name: 1 }} onSubmit={onSubmit}>
            <Form>
                <div className="columns p-2">
                    <div className="column">
                        <div className="columns">
                            <div className="column">
                                <h4 className="title is-5 mb-2 is-capitalized">{item.name}</h4>
                                <div className="columns is-vcentered">
                                    <h5 className="column is-4">Количество({measurement(item.measurement)}):</h5>
                                </div>
                                <div className="is-flex mb-4">
                                    <h1>Сумма:&nbsp;&nbsp;</h1>
                                    <b>
                                        <h1 className="has-text-danger">
                                            {format(item.usdPriceSale ? item.usdPriceSale : item.priceSale)} Сум
                                        </h1>
                                    </b>
                                </div>

                                <Select
                                    className="mb-4"
                                    name="order"
                                    label="Чек номи"
                                    options={regionOptions} />

                                <div className="columns is-vcentered">
                                    <div className="column">
                                        <input
                                            value={count}
                                            className="input"
                                            name="name"
                                            onChange={(e) => setCount(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="has-background-black-light" />
                        <div className="columns is-justify-content-flex-end mr-1">
                            <h5 className="column">
                                {format(count * item.usdPriceSale ? count * item.usdPriceSale : count * item.priceSale)} Сум
                            </h5>
                            <div>
                                <Button type="submit" className="is-success mr-3" text="Заказать" />
                                <Button className="is-danger" onClick={hideModal} text="Назад" />
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    )
}
