import React from 'react'
import { Form, Formik } from 'formik'

import Input from './common/Input'
import Button from './common/Button'
import Select from './common/Select'
import { currency, paymentTypes } from '../utils/types'
import { required } from '../utils/validators'

export default function BorrowInForm({
    onCancel, onSubmit, initialValues, loading,
}) {
    return (
        <Formik onSubmit={onSubmit} initialValues={{
            name: '',
            currency: 'sum',
            description: '',
            amountIn: '',
            termDate: '',
            status: 'paid',
            paymentType: 'cash',
            ...initialValues
        }}>
            <Form>

                <Select optionValue="value" optionLabel="name" empty options={paymentTypes} name="paymentType"
                    label="Способ оплаты" />
                <Select optionValue="value" optionLabel="name" empty options={currency} name="currency"
                    label="Выберите тип оплаты" />

                <Input name="amountIn" type="number" label="Сумма" validate={required} />

                <Input name="description" label="Примечание" />
                <div className="is-pulled-right">
                    <Button onClick={onCancel} icon="close-outline" text="Закрыть" className="is-danger" />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сохранить" icon="add-outline" type="submit" className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
