import React, { useCallback } from 'react'
import cn from 'classnames'
import debounce from 'lodash/debounce'

export default function SearchFilter2({ name = 'search', pageKey = 'page', setSearchParams, params = {}, ...attributes }) {
    const defaultValue = params?.[name] || ''

    const debouncedSearch = debounce((search) => {
        setSearchParams((prev) => ({ ...prev, [name]: search, [pageKey]: undefined }))
    }, 500)
    const onChange = useCallback(
        ({ target: { value } }) => {
            debouncedSearch(value)
        },
        [debouncedSearch],
    )

    return (
        <div style={{ width: '100%' }} className="control has-icons-left is-expanded">
            <input type="search" defaultValue={defaultValue} onChange={onChange} className={cn('input')} placeholder="Поиск..." {...attributes} />
            <span className="icon is-small is-left">
                <ion-icon name="search" />
            </span>
        </div>
    )
}
