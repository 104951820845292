import React from 'react'
import moment from 'moment'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'

import { format } from '../utils/number'
import { DATETIME_FORMAT } from '../utils/date'
import { getMeasurement } from '../utils/types'
import empty from '../static/empty-product.png'

export default function BorrowOrderInfo({ item, debt }) {
    function products() {
        return item?.orderProduct?.map((prod, i) => (
            <div key={prod?.id} style={{ marginBottom: '1rem' }}>
                <h3 className={cn(prod.type === 'returned' ? 'has-text-danger' : '')} style={{ fontWeight: 'bold' }}>
                    {i + 1}. {prod?.product?.name}
                </h3>
                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                    <span>Цена:</span>
                    <span style={{ fontWeight: 500 }}>
                        {prod?.count} {getMeasurement(prod?.product?.measurement)} x {format(prod?.price)} {item?.product?.paymentType === 'usd' ? 'USD' : 'Сум'} ={' '}
                        <b>
                            {format(prod?.count * prod.price)} {item?.product?.paymentType === 'usd' ? 'USD' : 'Сум'}
                        </b>
                    </span>
                </p>
            </div>
        ))
    }

    const notFound = () => (
        <div>
            <div className="is-flex is-justify-content-center is-align-items-center">
                <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
            </div>
        </div>
    )

    return (
        <>
            <main style={{ marginBottom: 100 }}>
                {item?.client && (
                    <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                        <b>Клиент:</b>
                        <span style={{ fontWeight: 500 }}>{item.client?.name}</span>
                    </p>
                )}
                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                    <b>Дата:</b>
                    <span style={{ fontWeight: 500 }}>{moment(item?.createdAt).format(DATETIME_FORMAT)}</span>
                </p>
                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                    <b>ID-чека:</b>
                    <span style={{ fontWeight: 500 }}>{item?.codeContent}</span>
                </p>

                <div style={{ height: 1, backgroundColor: 'black', margin: '8px 0' }} />

                {item?.orderProduct ? products() : notFound()}

                <div style={{ height: 1, backgroundColor: 'black', margin: '10px 0' }} />

                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                    <span style={{ fontWeight: 'bold' }}>Итого:</span>
                    <b>
                        {format(item?.amount)} {item?.currency === 'usd' ? 'USD' : 'Сум'}
                    </b>
                </p>
                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                    <span style={{ fontWeight: 'bold' }}>Долг:</span>
                    <b>
                        {format(debt)} {item?.currency === 'usd' ? 'USD' : 'Сум'}
                    </b>
                </p>
                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                    <span style={{ fontWeight: 'bold' }}>Выданная сумма:</span>
                    <b>
                        {format(item?.amount - debt)} {item?.currency === 'usd' ? 'USD' : 'Сум'}
                    </b>
                </p>
            </main>
        </>
    )
}

const styles = StyleSheet.create({ space: { marginTop: '2rem' }, emptyImg: { width: '330px', height: '300px' }, loader: { marginTop: '2rem' }, modal: { width: 800 } })
