import React, { useContext } from 'react'
import { Context } from '../components/common/BaseContext'

export function useRightModal(component, helper) {
    const { setRightModalComponent } = useContext(Context)

    function showModal(props) {
        const content = React.isValidElement(component) ? React.cloneElement(component, props) : component(props)
        setRightModalComponent({ content, helper })
    }

    function hideModal() {
        setRightModalComponent(null)
    }

    return [showModal, hideModal]
}
