import React from 'react'
import Input from '../../../components/common/Input'

export const CustomPriceInput = ({ values, item, index, loading }) => (
    <td>
        {values.currency === 'sum' && values.tradeType === 'retail' && <Input value={item?.sumPriceSale ?? 0} type="number" className="is-size-7" name={`products[${index}].sumPriceSale`} disabled={loading} />}
        {values.currency === 'sum' && values.tradeType === 'wholesale' && <Input value={item?.sumPriceTransfer ?? 0} type="number" className="is-size-7" name={`products[${index}].sumPriceTransfer`} disabled={loading} />}
        {values.currency === 'usd' && values.tradeType === 'retail' && <Input value={item?.usdPriceSale ?? 0} type="number" className="is-size-7" name={`products[${index}].usdPriceSale`} disabled={loading} />}
        {values.currency === 'usd' && values.tradeType === 'wholesale' && <Input value={item?.usdPriceTransfer ?? 0} type="number" className="is-size-7" name={`products[${index}].usdPriceTransfer`} disabled={loading} />}
    </td>
)
