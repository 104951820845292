import React from 'react'
import { StyleSheet } from 'aphrodite'
import Button from './common/Button'
import { useModal } from '../hooks/modal'
import ProductUpdate from './ProductUpdate'
import ProductDetailModal from './ProductDetailModal'
import { format } from '../utils/number'
import { getCurrency } from '../utils/types'
import { measurement } from '../utils/position'

export default function ProductItem({
    check,
    index,
    categoryList,
    item,
    user,
    brandList,
    products,
}) {
    const [showDetailModal, hideDetailModal] = useModal(
        <ProductDetailModal
            check={check}
            orderId={item.id}
            user={user}
            item={item}
            hideModal={() => hideDetailModal()}
        />,
        styles.modal,
    )

    const [showUpdateModal, setShowUpdateModal] = useModal(
        <ProductUpdate
            products={products}
            categoryList={categoryList}
            brandList={brandList}
            item={item}
            hideModal={() => setShowUpdateModal()}
        />,
        styles.modal,
    )

    return (
        <tr className="is-capitalized" key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td onClick={() => showDetailModal()} className="is-size-6">{item.name}</td>
            <td className="is-size-6">{item.count} {measurement(item.measurement)}</td>
            <td className="is-size-6">{format(item.priceSale)} {getCurrency(item.paymentType)}
                {item.usdPriceSale
                    ? (
                        <span
                            className="tag is-medium is-link is-light ml-2">{`${format(item.usdPriceSale)} Сум`}
                        </span>
                    )
                    : null}
            </td>

            <td className="has-text-right">
                <Button
                    onClick={showUpdateModal}
                    className="mr-2 button is-success is-outlined is-small"
                    icon="pencil-outline" />

            </td>
        </tr>
    )
}

const styles = StyleSheet.create({
    tr: {
        ':nth-child(1n) > td': {
            lineHeight: 1,
        },
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        // height: '100%',
    },

    td1: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        lineHeight: 1,
        cursor: 'pointer',
    },
    td1Img: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        objectFit: 'cover',
    },
    td1Right: {

    },
    td1RightTitle: {
        fontFamily: 'GilroySemi',
        color: '#333333',
        lineHeight: '19px',
    },
    td1RightSubtitle: {
        marginTop: '1px',
        fontFamily: 'Gilroy',
        fontSize: '14px',
        color: 'rgba(51, 51, 51, 0.7)',
        lineHeight: '16px',
    },
    industries: {
        display: 'flex',
        whiteSpace: 'nowrap',
        flexWrap: 'wrap',
    },
    modal: {
        width: 1000,
    },
})
