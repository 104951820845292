import { StyleSheet, css } from 'aphrodite'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import queryString from 'querystring'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import CheckCreate from '../../components/CheckCreate'
import Layout from '../../components/Layout'
import Search from '../../components/Search'
import Button from '../../components/common/Button'
import Table from '../../components/common/Table'
import { useModal } from '../../hooks/modal'
import { useQueryParams } from '../../hooks/queryString'
import { useGetRequest, useLoad } from '../../hooks/request'
import empty from '../../static/empty-shop.png'
import { PRODUCT_EXPORT, domain } from '../../urls'
import PromotionColumn from './Column'
import PromotionCreate from './promotion-create'

export default function Promotion() {
    const params = useQueryParams()
    const [page, setPage] = useState(1)
    const products = {
        response: {
            results: [
                { name: 'БЕК СТРОЙ МАРКЕТ БОБИР +998999693505', count: 'КАССИР', cashier: 'Feruzjon Xayrulloyev', type: 'ОЗОДБЕК', note: 'tarif', price: '4000000', date: '17.06.2024 12:45' },
                { name: 'БЕК СТРОЙ МАРКЕТ БОБИР +998999693505', count: 'КАССИР', cashier: 'Feruzjon Xayrulloyev', type: 'ОЗОДБЕК', note: 'tarif', price: '4000000', date: '17.06.2024 12:45' },
            ],
        },
    }
    const productList = products.response ? products.response.results : []
    const check = useLoad({ url: '/main/order' })
    const navigate = useHistory()
    const excelProduct = useGetRequest({ url: PRODUCT_EXPORT })
    const excelItemProduct = excelProduct.response ? excelProduct.response.fileUrl || excelProduct.response.file : null

    const [showCreateModal, setShowCreateModal] = useModal(<PromotionCreate reload={products} onCancel={() => setShowCreateModal()} />, styles.modal)
    const [showCreateCheck, hideCreateCheck] = useModal(<CheckCreate reload={check} onCancel={() => hideCreateCheck()} />)

    useEffect(() => {
        if (excelItemProduct && !isEmpty(excelItemProduct)) {
            const a = document.createElement('a')
            a.href = `${domain}${excelItemProduct}`
            a.setAttribute('file', 'response')
            a.click()
        }
    }, [excelItemProduct])

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        navigate(`?${queryString.stringify({ ...params, search })}`)
    }

    return (
        <Layout showCreateModal={showCreateModal} showCreateCheck={showCreateCheck}>
            <h1 className="is-size-4 has-text-weight-medium" style={{ marginBottom: '20px' }}>
                Акции
            </h1>
            <div className="is-flex is-justify-content-space-between mb-3">
                <Search onSearch={onSearch} style={{ width: '40%' }} />

                <div className="is-flex">
                    <Button text="Добавить" icon="add-outline" className="button is-link is-outlined ml-2" onClick={showCreateModal} />
                </div>
            </div>

            <div className="mt-4">
                {products.response && products.response.count === 0 ? (
                    <div className="is-flex is-justify-content-center is-align-items-center hei">
                        <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                    </div>
                ) : (
                    <Table
                        loading={products.loading}
                        items={productList}
                        columns={{
                            id: '',
                            store: 'Торговая точка',
                            name: 'Имя',
                            product: 'Продукт',
                            priceSale: 'Штрих-код',
                            quantity: 'Количество',
                            gift: 'В подарок',
                            giftBarcode: 'В подарок штрих-код',
                            giftQuantity: 'Количество',
                            activated: 'Активирован',
                            action: 'Действие',
                        }}
                        totalCount={products.response ? products.response.count : 0}
                        pageSize={15}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item, index) => <PromotionColumn index={index} products={products} check={check} key={item.id} item={item} />}
                    />
                )}
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: { marginTop: '2rem' },
    emptyImg: { width: '330px', height: '300px' },
    loader: { marginTop: '2rem' },
    modal: { width: 800 },
    checkmark: { marginRight: '3px' },
})
