import React, { useEffect } from 'react'
import Select from 'react-select'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useQueryParams } from '../../hooks/queryString'

export default function SupplierSelect({ name, defaultValue, options = [], pageKey = 'page', optionValueKey = 'id', optionLabelKey = 'name', ...props }) {
    const history = useHistory()
    const params = useQueryParams()
    const currentVal = options?.find((opt) => Number(opt[optionValueKey]) === Number(params[name]))

    useEffect(() => {
        if (defaultValue && !currentVal) {
            history.push(`?${queryString.stringify({ ...params, [name]: defaultValue[optionValueKey] })}`)
        }
    }, [currentVal, defaultValue, history, name, optionValueKey, pageKey, params])

    const handleChange = (newVal) => {
        history.push(`?${queryString.stringify({ ...params, [name]: newVal?.[optionValueKey] })}`)
    }

    return (
        <Select
            defaultValue={currentVal || defaultValue}
            value={currentVal || defaultValue}
            onChange={handleChange}
            options={options}
            getOptionValue={(opt) => opt[optionValueKey]}
            getOptionLabel={(opt) => opt[optionLabelKey]}
            isClearable
            styles={{
                container: (base) => ({ ...base, minWidth: '15rem' }),
                control: (base) => ({ ...base, height: '40px' }),
                placeholder: (base) => ({ ...base, whiteSpace: 'nowrap' }),
                indicatorSeparator: (base) => ({ ...base, display: 'none' }),
            }}
            {...props}
            isMulti={false}
        />
    )
}
