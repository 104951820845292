import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { format } from '../utils/number'
import { getDateTime } from '../utils/date'
import Button from './common/Button'
import ComponentToPrint from './PrintBarCode'
import ClientOrderTableItemDetail from './ClientOrderTableItemDetail'
import { useRightModal } from '../hooks/useRightModal'

export default function ClientOrderTableItem({ item }) {
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const [showDetail, hideDetail] = useRightModal(
        <ClientOrderTableItemDetail
            onCancel={() => hideDetail()}
            item={item}
        />,
    )

    return (
        <>
            <tr className="pointer" onClick={showDetail}>
                <td>{format(item?.sumComing)}</td>
                <td>{getDateTime(item?.createdAt)}</td>
                <td>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            handlePrint()
                        }}
                        className="button is-link mr-2 is-outlined is-small ml-2"
                        icon="print" />
                    <div style={{ display: 'none' }}>
                        <ComponentToPrint result={item.totalForOrderHistories} item={item} ref={componentRef} />
                    </div>
                </td>
            </tr>
        </>
    )
}
