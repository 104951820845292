import React from 'react'
import Select from 'react-select'
import { StyleSheet, css } from 'aphrodite'
import { Field } from 'formik'
import cn from 'classnames'
import ValidationErrorMessage from './ValidationErrorMessage'
import { Arrow24, Clear24Icon } from '../Svgs'

export default function CustomSelect({ options, placeholder, className, onSelectItem = () => {}, getOptionValue = (opt) => opt.id, label, name, validate, bgColor = 'transparent', pl = 0, regionName, isDisabled, ...props }) {
    // eslint-disable-next-line
    const ClearIndicator = (props) => {
        const {
            children = <Clear24Icon />,
            innerProps: { ref, ...restInnerProps },
        } = props
        return (
            <div {...restInnerProps} ref={ref} className="is-flex is-align-items-center">
                <div style={{ padding: '5px 5px', display: 'flex', alignItems: 'center' }}>{children}</div>
            </div>
        )
    }

    const DropdownIndicator = ({ isFocused }) => <i className="is-flex is-align-items-center">{isFocused ? <Arrow24 className={css(styles.icon2)} /> : <Arrow24 className={css(styles.icon)} />}</i>
    const customStyles = {
        control: (base, { isFocused }) => ({ ...base, boxShadow: isFocused ? 0 : 0, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '100%', padding: '0 20px 0 10px', borderRadius: 6 }),
        indicatorSeparator: (base) => ({ ...base, display: 'none', borderColor: '#9C9C9C' }),
        indicatorContainer: () => ({ padding: 0, display: 'flex', alignItems: 'center', svg: { path: { fill: '#000' } } }),
        dropdownIndicator: (base, { isFocused }) => ({ ...base, opacity: 80, padding: '8px 10px 8px 4px', transform: isFocused ? 'rotate(180deg' : null, transition: 'transform 0.2s linear' }),
        container: (base) => ({ ...base, height: 50, width: '100%', backgroundColor: bgColor, borderRadius: 5, cursor: 'pointer' }),
        placeholder: (base) => ({ ...base, paddingLeft: pl }),
        menu: (base) => ({ ...base, backgroundColor: '#fff', boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)', zIndex: 99 }),
        menuList: (base) => ({ ...base }),
    }

    return (
        <div className={cn('field mt-5', css(isDisabled && styles.notAllowed))}>
            <div className="is-flex is-flex-direction-column">
                {label ? <label className={cn('pl-2 gilroyMd', css(styles.label))}>{label}</label> : null}
                <Field name={name} validate={validate}>
                    {({ form, field }) => (
                        <Select
                            name={name}
                            className={className}
                            styles={customStyles}
                            components={{ DropdownIndicator, ClearIndicator }}
                            isClearable
                            placeholder={placeholder}
                            getOptionValue={getOptionValue}
                            onBlur={form.setFieldTouched}
                            value={field.value}
                            options={options}
                            maxMenuHeight="200px"
                            menuPlacement="auto"
                            onChange={(values) => {
                                onSelectItem(values)
                                form.setFieldValue(name, values)
                                if (regionName) {
                                    form.setFieldValue(regionName, null)
                                }
                            }}
                            isDisabled={isDisabled}
                            {...props}
                        />
                    )}
                </Field>

                <ValidationErrorMessage name={name} />
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    label: {
        fontSize: '14px',
        lineHeight: '24px',
        color: '#1D1E22',
    },
    icon: {
        transform: 'rotate(90deg)',
        transition: 'transform 0.2s linear',
    },
    icon2: {
        transform: 'rotate(270deg)',
        transition: 'transform 0.2s linear',
    },
    notAllowed: {
        cursor: 'not-allowed',
    },
})
