import React, { useEffect } from 'react'
import Select from 'react-select'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useQueryParams } from '../../hooks/queryString'

export default function FilterSelect({ name, defaultValue, options = [], pageKey = 'page', optionValueKey = 'id', optionLabelKey = 'name', ...props }) {
    const history = useHistory()
    const params = useQueryParams()
    const currentVal = options.find((opt) => String(opt[optionValueKey]) === params[name])

    useEffect(() => {
        if (defaultValue && !currentVal) {
            history.push(`?${queryString.stringify({ ...params, [name]: defaultValue[optionValueKey], [pageKey]: 1 })}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (newVal) => {
        history.push(`?${queryString.stringify({ ...params, [name]: newVal?.[optionValueKey], [pageKey]: 1 })}`)
    }

    return (
        <Select
            defaultValue={currentVal || defaultValue}
            onChange={handleChange}
            options={options}
            getOptionValue={(opt) => opt[optionValueKey]}
            getOptionLabel={(opt) => opt[optionLabelKey]}
            isClearable
            styles={{ container: (base) => ({ ...base, minWidth: '10rem' }), control: (base) => ({ ...base, height: '40px' }), placeholder: (base) => ({ ...base, whiteSpace: 'nowrap' }), indicatorSeparator: (base) => ({ ...base, display: 'none' }) }}
            {...props}
            isMulti={false}
        />
    )
}
