import React from 'react'
import { usePostRequest } from '../hooks/request'
import { CLIENT_LIST, ORDER_LIST } from '../urls'
import ClientForm from './ClientForm'
import { useMessage } from '../hooks/message'

export default function ClientCreate({
    onCancel,
    active,
    reload,
}) {
    const [showMessage] = useMessage()
    const client = usePostRequest({ url: CLIENT_LIST })
    const orders = usePostRequest({ url: ORDER_LIST })

    async function onSubmit(data, action) {
        const {
            success,
            response,
            error,
        } = await client.request({ data })

        if (active && response) {
            await orders.request({
                data: {
                    client: response.id,
                    items: [],
                },
            })
        }
        if (success) {
            onCancel()
            action.resetForm()
            showMessage('Успешно добавлен', 'is-success')
            if (!active) {
                orders.request()
            }
            reload.request()
        }
        if (error) {
            showMessage(error?.data?.detail || 'Ошибка', 'is-danger')
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered is-capitalized"><b>добавить новый клиента</b></h1>
            <ClientForm
                onCancel={onCancel}
                onSubmit={onSubmit} />
        </div>

    )
}
