import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { FieldArray, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'

import { PRODUCT_LIST } from '../../urls'
import ClientSelect from './ClientSelect'
import PaymentModal from './PaymentModal'
import { format } from '../../utils/number'
import Card from '../../components/common/Card'
import { useMessage } from '../../hooks/message'
import Table from '../../components/common/Table'
import Input from '../../components/common/Input'
import Button from '../../components/common/Button'
import ReactModal from '../../components/common/ReactModal'
import { useLoad, usePutRequest } from '../../hooks/request'
import { CustomPriceInput } from './common/CustomPriceInput'
import SearchFilter2 from '../../components/common/SearchFilter2'
import CurrencySelect from '../../components/common/CurrencySelect'
import TradeTypeSelect from '../../components/common/TradeTypeSelect'
import { currency, getCurrency, getMeasurement, tradeTypes } from '../../utils/types'

export default function CashRegisterForm({ formData, updateFormData, cash }) {
    const [showMessage] = useMessage()
    const [params, setParams] = useState({})
    const { request, loading } = usePutRequest()
    const [isPaymentOpen, setIsPaymentOpen] = useState(false)

    const productsLoad = useLoad({ url: PRODUCT_LIST, params: { ...params, size: 12 } }, [params])
    const productsList = productsLoad.response?.results || []
    const products = productsList.map((item) => ({ ...item, count: item?.count || 0, priceSale: item?.priceSale || '', quantity: '1' }))

    const handleSubmit = async (vals) => {
        const { success } = await request({
            url: `main/order_simple_update/${vals.id}`,
            data: {
                items: vals.products.map((product) => ({
                    count: product.count ? product.count : 0,
                    sum_price: product.sum_price ? product.sumPrice : 0,
                    sum_price_sale: product.sum_price_sale ? product.sumPriceSale : 0,
                    sum_price_transfer: product.sum_price_transfer ? product.sumPriceTransfer : 0,
                    usd_price: product.usd_price ? product.usdPrice : 0,
                    usd_price_sale: product.usd_price_sale ? product.usdPriceSale : 0,
                    usd_price_transfer: product.usd_price_transfer ? product.usdPriceTransfer : 0,
                    ...product,
                })),
            },
        })

        if (success) {
            setIsPaymentOpen(false)
            cash.request()
            showMessage('Успешно сохранен', 'is-success')
        }
    }

    const localOrders = localStorage.getItem('orders') ? JSON.parse(localStorage.getItem('orders')) : []
    const localOrder = localOrders?.find((order) => order?.id === formData?.id)
    const localProducts = localOrder?.products || []

    return (
        <Formik enableReinitialize initialValues={{ tradeType: tradeTypes[0].value, currency: currency[0].value, client: null, products: localProducts, cash: '', bankCard: '', isLend: false, ...formData }}>
            {({ setFieldValue, values }) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEffect(() => {
                    const orders = JSON.parse(localStorage.getItem('orders')) || []
                    // eslint-disable-next-line no-shadow
                    const order = orders?.find((order) => order?.id === formData?.id)
                    if (order) {
                        order.products = values.products
                        localStorage.setItem('orders', JSON.stringify(orders))
                    }

                    updateFormData(values)
                }, [values])

                const totals = values.products.reduce(
                    (acc, product) => {
                        const quantity = product.quantity ? +product.quantity : 0
                        const getPrice = () => {
                            if (values.currency === 'sum') {
                                if (values.tradeType === 'retail') return product?.sumPriceSale || 0
                                if (values.tradeType === 'wholesale') return product?.sumPriceTransfer || 0
                            } else if (values.currency === 'usd') {
                                if (values.tradeType === 'retail') return product?.usdPriceSale || 0
                                if (values.tradeType === 'wholesale') return product?.usdPriceTransfer || 0
                            }
                            return 0
                        }

                        const price = getPrice()
                        acc.totalQuantity += quantity
                        acc.totalSaleSum += quantity * price
                        return acc
                    },
                    { totalQuantity: 0, totalSaleSum: 0 }
                )
                const paidAmount = +values.cash || +values.bankCard
                return (
                    <Form>
                        <header style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 200px))' }}>
                            <TradeTypeSelect name="tradeType" label="Тип торговли" options={tradeTypes} optionValue="value" disabled={loading} />
                            <CurrencySelect name="currency" label="Валюта" options={currency} optionValue="value" disabled={loading} />
                            <ClientSelect formData={formData} values={values} setFieldValue={setFieldValue} />
                        </header>
                        <Card>
                            <FieldArray
                                name="products"
                                render={(arrayHelpers) => {
                                    console.log('arrayHelpers')
                                    return (
                                        <>
                                            <Table
                                                emptyMessage="Добавьте товар"
                                                columns={{ name: 'Наименование товара', remains: 'Остаток', quantity: 'Количество', measurement: 'Ед. изм.', priceSale: 'Цена за единицу', currency: 'Валюта', summ: 'K оплате', actions: '' }}
                                                items={values.products}
                                                renderItem={(item, index) => {
                                                    const quantity = +values.products?.[index].quantity || 0
                                                    const getPrice = () => {
                                                        if (values.currency === 'sum') {
                                                            if (values.tradeType === 'retail') return item?.sumPriceSale || 0
                                                            if (values.tradeType === 'wholesale') return item?.sumPriceTransfer || 0
                                                        } else if (values.currency === 'usd') {
                                                            if (values.tradeType === 'retail') return item?.usdPriceSale || 0
                                                            if (values.tradeType === 'wholesale') return item?.usdPriceTransfer || 0
                                                        }
                                                        return 0
                                                    }
                                                    const price = getPrice()
                                                    const sum = format(quantity * price)
                                                    return (
                                                        <tr key={item.id}>
                                                            <td>{item.name}</td>
                                                            <td>{item.count}</td>
                                                            <td>
                                                                <Input
                                                                    validate={(val) => {
                                                                        let error
                                                                        if (!val) error = 'Это поле обязательно'
                                                                        if (val === '0') error = 'Это поле обязательно'
                                                                        return error
                                                                    }}
                                                                    type="number"
                                                                    className="is-size-7"
                                                                    name={`products[${index}].quantity`}
                                                                    disabled={loading}
                                                                />
                                                            </td>
                                                            <td>{getMeasurement(item.measurement)}</td>
                                                            <CustomPriceInput index={index} item={item} loading={loading} values={values} />
                                                            <td> {getCurrency(values.currency)}</td>
                                                            <td>{format(sum) ?? ''}</td>
                                                            <td>
                                                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                                                    <Button
                                                                        onClick={() => {
                                                                            const orders = localStorage.getItem('orders') ? JSON.parse(localStorage.getItem('orders')) : []
                                                                            const order = orders.find((o) => o?.id === formData?.id)
                                                                            if (order) {
                                                                                order.products = order.products.filter((p) => p?.id !== item?.id)
                                                                                orders[orders.indexOf(order)] = order
                                                                                localStorage.setItem('orders', JSON.stringify(orders))
                                                                            }
                                                                            arrayHelpers.remove(index)
                                                                        }}
                                                                        icon="close-outline"
                                                                        className="is-danger is-outlined is-size-7"
                                                                        disabled={loading}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }}
                                            />
                                            <hr />
                                            <main style={{ display: 'flex', gap: '1rem' }} className="mt-6">
                                                <div style={{ flex: 'auto' }}>
                                                    <div className="mb-2">
                                                        <SearchFilter2 setSearchParams={setParams} />
                                                    </div>
                                                    <Table
                                                        columns={{ code: 'Штрих-код', name: 'Наименование товара', quantity: 'Остаток', priceSale: 'Цена' }}
                                                        items={products.filter((prod) => !values.products.map((p) => p?.id).includes(prod?.id))}
                                                        renderItem={(item) => (
                                                            <tr
                                                                className={cn(css(styles.product))}
                                                                key={item?.id}
                                                                onClick={() => {
                                                                    const payload = {
                                                                        ...item,
                                                                        sumPrice: item?.sumPrice ? item.sumPrice.toFixed(2).replace(/\.00$/, '') : 0,
                                                                        sumPriceSale: item?.sumPriceSale ? item.sumPriceSale.toFixed(2).replace(/\.00$/, '') : 0,
                                                                        sumPriceTransfer: item?.sumPriceTransfer ? item.sumPriceTransfer.toFixed(2).replace(/\.00$/, '') : 0,
                                                                        usdPrice: item?.usdPrice ? item.usdPrice.toFixed(2).replace(/\.00$/, '') : 0,
                                                                        usdPriceSale: item?.usdPriceSale ? item.usdPriceSale.toFixed(2).replace(/\.00$/, '') : 0,
                                                                        usdPriceTransfer: item?.usdPriceTransfer ? item.usdPriceTransfer.toFixed(2).replace(/\.00$/, '') : 0,
                                                                    }
                                                                    if (!loading) {
                                                                        const orders = localStorage.getItem('orders') ? JSON.parse(localStorage.getItem('orders')) : []
                                                                        if (orders.length) {
                                                                            const ordersArray = orders
                                                                            // eslint-disable-next-line no-shadow
                                                                            const order = ordersArray.find((order) => order.id === formData?.id)
                                                                            if (order) {
                                                                                order.products.push(payload)
                                                                                ordersArray.splice(ordersArray.indexOf(order), 1)
                                                                                localStorage.setItem('orders', JSON.stringify([...ordersArray, order]))
                                                                            } else localStorage.setItem('orders', JSON.stringify([...ordersArray, { ...values, products: [...values.products, payload] }]))
                                                                        } else localStorage.setItem('orders', JSON.stringify([{ ...values, products: [...values.products, payload] }]))
                                                                        arrayHelpers.push(payload)
                                                                    }
                                                                }}
                                                            >
                                                                <td>{item?.barcode}</td>
                                                                <td>{item?.name}</td>
                                                                <td>
                                                                    {item?.count || 0} {getMeasurement(item?.measurement)}
                                                                </td>
                                                                <td>
                                                                    {values.currency === 'sum' && values.tradeType === 'retail' && format(item?.sumPriceSale)}
                                                                    {values.currency === 'sum' && values.tradeType === 'wholesale' && format(item?.sumPriceTransfer)}
                                                                    {values.currency === 'usd' && values.tradeType === 'retail' && format(item?.usdPriceSale)}
                                                                    {values.currency === 'usd' && values.tradeType === 'wholesale' && format(item?.usdPriceTransfer)} {getCurrency(values.currency)}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    />
                                                </div>

                                                <aside style={{ minWidth: 300 }}>
                                                    <div className="tag is-large is-link is-light">
                                                        К ОПЛАТЕ: <b className="mx-1">{totals.totalSaleSum.toFixed(2)}</b> {getCurrency(values.currency)}
                                                    </div>

                                                    <div style={{ display: 'grid', gap: '1rem' }} className="mt-4">
                                                        <Button
                                                            onClick={() => {
                                                                setIsPaymentOpen(true)
                                                                setFieldValue('cash', totals.totalSaleSum)
                                                            }}
                                                            text="Оплата"
                                                            icon="shield-checkmark-outline"
                                                            className="is-success"
                                                            disabled={loading || values.products.length === 0}
                                                        />

                                                        <ReactModal style={{ content: { minWidth: 550 } }} isOpen={isPaymentOpen} setIsOpen={setIsPaymentOpen}>
                                                            <PaymentModal orderId={formData?.id} cash={cash} handleSubmit={handleSubmit} loading={loading} paidAmount={paidAmount} setFieldValue={setFieldValue} totals={totals} values={values} setIsPaymentOpen={setIsPaymentOpen} />
                                                        </ReactModal>
                                                        <Button onClick={() => updateFormData({})} icon="close-outline" text="Отмена" className="is-outlined is-danger" disabled={loading} />
                                                    </div>
                                                </aside>
                                            </main>
                                        </>
                                    )
                                }}
                            />
                        </Card>
                    </Form>
                )
            }}
        </Formik>
    )
}

const styles = StyleSheet.create({ product: { ':hover': { backgroundColor: 'aliceblue', cursor: 'pointer' } } })
