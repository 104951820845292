import React from 'react'
import { usePostRequest } from '../hooks/request'
import { CATEGORY_LIST } from '../urls'
import CategoryForm from './CategoryForm'
import { useMessage } from '../hooks/message'

export default function CategoryCreate({ onCancel, category, reload }) {
    const categoryCreate = usePostRequest({ url: CATEGORY_LIST })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await categoryCreate.request({ data })

        if (success) {
            showMessage('Успешно сохранен', 'is-success')
            reload.request()
            onCancel()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered has-text-weight-bold">Добавить категорию</h1>

            <CategoryForm category={category} onCancel={onCancel} onSubmit={onSubmit} />
        </div>
    )
}
