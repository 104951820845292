import { StyleSheet } from 'aphrodite'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useLoad, usePostRequest } from '../../hooks/request'
import { useMessage } from '../../hooks/message'
import Layout from '../../components/Layout'
import { DOCUMENTS_LIST } from '../../urls'
import InventoryForm from './inventory-create-form'
import Table from '../../components/common/Table'
import InventoryCreateColumn from './inventory-create-column'
import { useModal } from '../../hooks/modal'
import Button from '../../components/common/Button'
import InventoryCreateModal from './inventory-create-modal'

export default function InventoryCreate({ userList, territory }) {
    const history = useHistory()
    const [showMessage] = useMessage()
    const [page, setPage] = useState(1)
    const check = useLoad({ url: '/main/order' })
    const market = usePostRequest({ url: DOCUMENTS_LIST })
    const [showCreateCheck, hideCreateCheck] = useModal(<InventoryCreateModal reload={check} onCancel={() => hideCreateCheck()} />, styles.modal)

    async function onSubmit(data) {
        const { success } = await market.request({ data })

        if (success) {
            showMessage('Успешно сохранен', 'is-success')
            history.push('/inventory')
        }
    }

    const products = {
        response: {
            results: [
                { name: 'БАРАШКА 25 ВАТЕР', documentNumber: '9998', status: '12', price: '4000000', startDate: 'шт', expirationDate: '23 500' },
                { name: 'БАРАШКА 25 ВАТЕР', documentNumber: '9958', status: '23', price: '4000000', startDate: 'шт', expirationDate: '45 000' },
            ],
        },
    }

    return (
        <Layout>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 className="title has-text-left">
                    <b>Инвентаризация Создать</b>
                </h3>
                <Button className="is-blue" onClick={showCreateCheck} text="Hе инвенторезанные" />
            </div>

            <InventoryForm loading={market.loading} userList={userList} onSubmit={onSubmit} territory={territory} />

            <div style={{ display: 'block', marginTop: 100 }}>
                <Table
                    items={products.response.results}
                    columns={{ index: '№', name: 'Наименование товара', documentNumber: 'Штрих-код', startDate: 'Ед. изм', expirationDate: 'Цена', status: 'Ожидаемый остаток', action: 'Посчитано' }}
                    totalCount={products.response.results?.length}
                    pageSize={15}
                    activePage={page}
                    onPageChange={setPage}
                    renderItem={(item, index) => <InventoryCreateColumn index={index} products={products.response.results} check={check} key={item.id} item={item} />}
                />
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({ modal: { width: '70vw', height: '80vh', borderRadius: 6 } })
