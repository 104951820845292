/* eslint-disable max-len */
import React from 'react'
import moment from 'moment'
import { DATETIME_FORMAT } from '../utils/date'
import { getMeasurement, getPaymentMethods } from '../utils/types'
import { format } from '../utils/number'
import Button from './common/Button'

export default function ClientOrderTableItemDetail({ item, onCancel }) {
    return (
        <>
            <main>
                {item?.client && (
                    <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                        <b>Клиент:</b>
                        <span style={{ fontWeight: 500 }}>
                            {item.client?.name}
                        </span>
                    </p>
                )}
                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                    <b>Дата:</b>
                    <span style={{ fontWeight: 500 }}>
                        {moment(item?.createdAt).format(DATETIME_FORMAT)}
                    </span>
                </p>
                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                    <b>ID-чека:</b>
                    <span style={{ fontWeight: 500 }}>
                        {item?.codeContent}
                    </span>
                </p>
                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                    <b>Способ оплаты:</b>
                    <span style={{ fontWeight: 500 }}>
                        {getPaymentMethods(item?.paymentMethod)}
                    </span>
                </p>

                <div style={{ height: 1, backgroundColor: 'black', margin: '8px 0' }} />

                {item?.orderProduct?.map((prod, i) => (
                    <div key={prod?.id} style={{ marginBottom: '1rem' }}>
                        <h3 style={{ fontWeight: 'bold' }}>{i + 1}. {prod?.product?.name}</h3>

                        <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                            <span>Цена:</span>
                            <span style={{ fontWeight: 500 }}>
                                {prod.count} {getMeasurement(prod?.product?.measurement)} x {format(prod.price)} сўм = <b>{format(prod.count * prod.price)} сўм</b>
                            </span>
                        </p>
                    </div>
                ))}

                <div style={{ height: 1, backgroundColor: 'black', margin: '10px 0' }} />

                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                    <span style={{ fontWeight: 'bold' }}>Итого:</span>
                    <b>
                        {format(item?.sumComing)} сўм
                    </b>
                </p>
            </main>

            <div
                style={{ marginTop: '1rem', display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}
            >
                <p />
                <Button
                    onClick={onCancel}
                    text="Закрыть"
                    className="is-outlined"
                />
            </div>
        </>
    )
}
