import React from 'react'
import { SUPPLIER_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import { useMessage } from '../hooks/message'
import SupplierForm from './SupplierForm'

export default function SupplierUpdate({ hideModal, item, reload, userList, territory }) {
    const marketUpdate = usePutRequest({ url: SUPPLIER_DETAIL.replace('{id}', item?.id) })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await marketUpdate.request({ data })
        if (success) {
            hideModal()
            showMessage('Успешно изменено', 'is-success')
            reload.request()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered">
                <b>Изменить магазины</b>
            </h1>

            <SupplierForm onCancel={hideModal} userList={userList} territory={territory} initialValues={{ ...item, user: item?.user?.id }} onSubmit={onSubmit} />
        </div>
    )
}
