import React, { useState } from 'react'

import Layout from '../components/Layout'
import { useLoad } from '../hooks/request'
import { CATEGORY_LIST } from '../urls'
import CategoryCreate from '../components/CategoryCreate'
import { useModal } from '../hooks/modal'
import { useQueryParams } from '../hooks/queryString'
import Table from '../components/common/Table'
import CategoryItem from '../components/CategoryItem'
import Button from '../components/common/Button'
import SearchFilter from '../components/common/SearchFIlter'

export default function Categories() {
    const params = useQueryParams()
    const [page, setPage] = useState(1)
    const categories = useLoad({ url: CATEGORY_LIST, params: { ...params, page, status: 'active' } }, [params, page])

    const [showCreateModal, setShowUpdateModal] = useModal(<CategoryCreate reload={categories} onCancel={() => setShowUpdateModal()} />)

    return (
        <Layout>
            <div className="is-flex mb-3">
                <SearchFilter />
                <Button text="Добавить" icon="add-outline" className="button is-link is-outlined ml-2 mr-2" onClick={showCreateModal} />
            </div>

            <Table
                loading={categories.loading}
                items={categories.response ? categories.response.results : []}
                columns={{ index: '', name: 'Название', actions: '' }}
                totalCount={categories.response ? categories.response.count : 0}
                pageSize={15}
                activePage={page}
                onPageChange={setPage}
                renderItem={(item, index) => <CategoryItem index={index} categories={categories} key={item.id} item={item} />}
            />
        </Layout>
    )
}
