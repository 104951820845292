import React from 'react'
import CategoryForm from './CategoryForm'
import { CATEGORY_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'

export default function CategoryUpdate({ hideModal, item, reload }) {
    const categoryUpdate = usePutRequest({ url: CATEGORY_DETAIL.replace('{id}', item.id) })

    async function onSubmit(data) {
        const { success } = await categoryUpdate.request({ data })
        if (success) {
            reload.request()
            hideModal()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered">
                <b>Изменить категории</b>
            </h1>

            <CategoryForm onCancel={hideModal} initialValues={item} onSubmit={onSubmit} />
        </div>
    )
}
