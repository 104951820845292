import React from 'react'
import { Form, Formik } from 'formik'
import Button from './common/Button'
import { required } from '../utils/validators'
import Input from './common/Input'
import Select from './common/Select'
import { currency, measurements } from '../utils/types'

export default function DocumentsProductForm({ onCancel, onSubmit, loading, initialValues }) {
    const handleSubmit = (vals, actions) => {
        const data = {
            ...vals,
            measurement: vals.measurement || undefined,
            paymentType: vals.paymentType || undefined,
        }
        onSubmit(data, actions)
    }
    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={{
                name: '',
                measurement: '',
                paymentType: '',
                barcode: '',
                ...initialValues,
            }}
        >
            <Form>
                <div className="columns">
                    <div className="column">
                        <Input name="name" label="Наименование товара" validate={required} disabled={loading} />

                        <Select optionValue="value" optionLabel="name" name="paymentType" label="Валюта" options={currency} disabled={loading} />

                        <Select name="measurement" label="Измерение" optionValue="value" optionLabel="name" options={measurements} disabled={loading} />

                        <Input name="barcode" type="number" label="Штрих-код" disabled={loading} />
                    </div>
                </div>

                <div className="is-pulled-right">
                    <Button onClick={onCancel} icon="close-outline" text="Закрыть" className="is-danger" disabled={loading} />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сохранить" icon="add-outline" type="submit" className="is-success" disabled={loading} />
                </div>
            </Form>
        </Formik>
    )
}
