import React from 'react'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

import Button from './common/Button'
import { BORROW_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import BorrowInUpdate from './BorrowInUpdate'
import { getDateOtherFormat, getDateTime } from '../utils/date'
import { useCustomModal } from '../hooks/customModal'
import { useRightModal } from '../hooks/useRightModal'
import BorrowOrderInfo from '../pages/BorrowOrderInfo'
import { getCurrency, getPaymentTypes } from '../utils/types'
import { measurement, status } from '../utils/position'

pdfMake.vfs = pdfFonts.pdfMake?.vfs

export default function ClientBorrowItem({ reload, item, borrows, index, model }) {
    const clientBorrowRemove = useDeleteRequest({ url: BORROW_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await clientBorrowRemove.request()
            borrows.request()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useCustomModal(<BorrowInUpdate borrows={borrows} reload={reload}
        item={item}
        hideModal={() => hideUpdateModal()} />)

    const currentDate = new Date()

    const [showInfoModal, hideInfoModal] = useRightModal((order) => <BorrowOrderInfo debt={item.amount} onCancel={() => hideInfoModal()} item={order} />)

    const handleClick = (order) => {
        showInfoModal(order)
    }

    const exportToPdf = () => {
        // eslint-disable-next-line camelcase
        const { selectClient, selectOrder } = item
        const orderItems = selectOrder?.orderProduct || []

        const docDefinition = {
            content: [
                { text: 'СЧЕТ-ФАКТУРА', style: 'header' },
                { text: `№ ${item.id} от ${getDateOtherFormat(item.created_at)} г.`, style: 'subheader' },
                { columns: [{ text: 'Поставщик' }, { text: 'SKLAT', bold: true }] },
                { columns: [{ text: 'Адрес' }, { text: 'SHOFIRKON', bold: true }] },
                { text: 'Покупатель', style: 'sectionTitle' },
                { columns: [{ text: `${selectClient?.name} ${selectClient?.phoneNumber}` }, { text: `Контакт: ${selectClient?.phoneNumber}` }] },
                { text: ' ' },
                { text: 'Товары', style: 'sectionTitle' },
                {
                    table: {
                        headerRows: 1,
                        widths: [30, '*', 50, 50, 60, 50],
                        body: [
                            [
                                { text: '№', style: 'tableHeader' },
                                { text: 'Наименование', style: 'tableHeader' },
                                { text: 'Ед. изм', style: 'tableHeader' },
                                { text: 'Кол-во', style: 'tableHeader' },
                                { text: 'Цена', style: 'tableHeader' },
                                { text: 'Сумма', style: 'tableHeader' },
                            ],
                            // eslint-disable-next-line no-shadow
                            ...orderItems.map((product, index) => [index + 1, product.product.name, measurement(product.product.measurement), product.count, format(product.price), format(product.price * product.count)]),
                        ],
                    },
                },
                { text: ' ' },
                {
                    columns: [
                        { text: 'Итого:', alignment: 'right', style: 'totalsLabel' },
                        { text: `${format(item.amount)}   ${getCurrency(item.currency)}`, alignment: 'right', style: 'totalsValue' },
                    ],
                },
            ],
            styles: {
                header: { fontSize: 18, bold: true, marginBottom: 10 },
                subheader: { fontSize: 12, marginBottom: 5 },
                sectionTitle: { fontSize: 14, bold: true, marginTop: 10, marginBottom: 5 },
                tableHeader: { bold: true, fontSize: 12, color: 'black' },
                totalsLabel: { bold: true, fontSize: 12, marginTop: 10 },
                totalsValue: { bold: true, fontSize: 12, marginTop: 10 },
            },
        }

        pdfMake.createPdf(docDefinition).download(`invoice_${item.id}.pdf`)
    }

    function format(value) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
            .format(value)
            .replace(/,/g, ' ')
    }

    return (
        <tr className={`${getDateOtherFormat(currentDate) > item.term ? 'has-text-danger is-size-6' : 'has-text-black is-size-6'}`}
            key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td>
                {item.amountOut > 0 ? `${format(item.amountOut)} ${getCurrency(item.currency)}` : '0.00'}
            </td>

            <td>
                {item.amountIn > 0 ? `${format(item.amountIn)} ${getCurrency(item.currency)}` : '0.00'}
            </td>

            <td className="is-size-6">{getPaymentTypes(item.paymentType)}</td>
            <td className="is-size-6">{status(item.status)}</td>
            <td style={{ width: 155 }} className="is-size-6">{getDateTime(item.createdAt)}</td>
            {
                model
                    ? (
                        <td style={{ width: 201 }}>
                            <div className="is-pulled-left">
                                <Button onClick={exportToPdf} className="mr-2 is-info is-outlined is-small" icon="print" />

                                <Button
                                    onClick={() => {
                                        handleClick(item.selectOrder)
                                    }}
                                    className="mr-2 is-info is-outlined is-small"
                                    icon="eye-outline"
                                />
                                <Button onClick={showUpdateModal} className="mr-2 is-success is-outlined is-small"
                                    icon="pencil-outline" />
                                <Button onClick={onDelete} className="is-danger is-outlined is-small" icon="trash" />
                            </div>
                        </td>
                    ) : null
            }
        </tr>
    )
}
