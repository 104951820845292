import { StyleSheet } from 'aphrodite'
import React from 'react'

import ProductHistory from '../../components/ProductHistory'
import Button from '../../components/common/Button'
import { useModal } from '../../hooks/modal'

export default function InventoryCreateColumn({ check, item, user, index }) {
    const [showDetailModal, hideDetailModal] = useModal(<ProductHistory check={check} productId={item.id} user={user} item={item} hideModal={() => hideDetailModal()} />, styles.modal)

    return (
        <tr className=" is-size-6" key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td className="is-size-6 is-capitalized" style={{ textWrap: 'nowrap' }}>
                БЕК СТРОЙ МАРКЕТ БОБИР +998999693505
            </td>
            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                {item.documentNumber}
            </td>
            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                {item.startDate}
            </td>
            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                {item.expirationDate}
            </td>
            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                <span className="has-text-success">{item.status}</span>
            </td>
            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                <Button text="-" onClick={showDetailModal} className="mr-2 button is-info is-danger is-outlined is-small" />
            </td>
        </tr>
    )
}

const styles = StyleSheet.create({ modal: { width: '90vw', height: '95vh', borderRadius: 6 } })
