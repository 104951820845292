import React from 'react'
import { usePostRequest } from '../hooks/request'
import { SUPPLIER_ITEM_LIST } from '../urls'
import SupplierItemForm from './SupplierItemForm'

export default function SupplierItemCreate({ onCancel, territory, supplierId, groupName, onSuccess }) {
    const supplierItem = usePostRequest({ url: SUPPLIER_ITEM_LIST })

    async function onSubmit(data) {
        const { success } = await supplierItem.request({ data: { ...data, supplier: supplierId } })

        if (success) {
            onSuccess()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Оплатить</b></h1>
            <div>
                <p className="has-text-black is-size-4">
                    Организация <span className="is-pulled-right">{groupName}</span>
                </p>
            </div>
            <br />

            <SupplierItemForm
                loading={supplierItem.loading}
                onCancel={onCancel}
                onSubmit={onSubmit}
                territory={territory} />
        </div>
    )
}
