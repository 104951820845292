import React, { useState } from 'react'

import ClientsList from './ClientsList'
import Button from '../../components/common/Button'
import ReactModal from '../../components/common/ReactModal'
import ValidationErrorMessage from '../../components/common/ValidationErrorMessage'

export default function ClientSelect({ values, setFieldValue, formData }) {
    const [clientsIsOpen, setClientsIsOpen] = useState(false)
    function showClients() {
        setClientsIsOpen(true)
    }
    function hideClients() {
        setClientsIsOpen(false)
    }

    return (
        <fieldset className="field">
            <div className="control">
                <label htmlFor="client">Клиент</label>
                <div>
                    <Button onClick={showClients} style={{ gap: '0.5rem' }} className="is-outlined is-fullwidth is-justify-content-flex-start" text={<>{values?.client?.name ? values?.client?.name : 'Выбрать'}</>} />
                    <ReactModal isOpen={clientsIsOpen} setIsOpen={setClientsIsOpen}>
                        <ClientsList formData={formData} onCancel={hideClients} setFieldValue={setFieldValue} />
                    </ReactModal>
                </div>
            </div>

            <ValidationErrorMessage name="client" />
        </fieldset>
    )
}
