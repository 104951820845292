import { StyleSheet } from 'aphrodite'
import React from 'react'

import ProductHistory from '../../components/ProductHistory'
import Button from '../../components/common/Button'
import { useModal } from '../../hooks/modal'

export default function InventoryColumn({ check, item, user, index }) {
    const [showDetailModal, hideDetailModal] = useModal(<ProductHistory check={check} productId={item.id} user={user} item={item} hideModal={() => hideDetailModal()} />, styles.modal)

    return (
        <tr className=" is-size-6" key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td className="is-size-6 is-capitalized" style={{ textWrap: 'nowrap' }}>
                БЕК СТРОЙ МАРКЕТ БОБИР +998999693505
            </td>

            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                {item.documentNumber}
            </td>

            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                {item.startDate}
            </td>

            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                {item.expirationDate}
            </td>

            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                <span className="has-text-success">{item.status}</span>
            </td>

            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                <Button onClick={showDetailModal} className="mr-2 button is-info is-outlined is-small" icon="eye" />
            </td>
        </tr>
    )
}

const styles = StyleSheet.create({
    tr: { ':nth-child(1n) > td': { lineHeight: 1 } },
    center: { display: 'flex', alignItems: 'center' },
    td1: { display: 'flex', alignItems: 'center', gap: '5px', lineHeight: 1, cursor: 'pointer' },
    td1Img: { width: 32, height: 32, borderRadius: '50%', objectFit: 'cover' },
    td1Right: {},
    td1RightTitle: { fontFamily: 'GilroySemi', color: '#333333', lineHeight: '19px' },
    td1RightSubtitle: { marginTop: '1px', fontFamily: 'Gilroy', fontSize: '14px', color: 'rgba(51, 51, 51, 0.7)', lineHeight: '16px' },
    industries: { display: 'flex', whiteSpace: 'nowrap', flexWrap: 'wrap' },
    modal: { width: '90vw', height: '95vh', borderRadius: 6 },
})
