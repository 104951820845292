import React from 'react'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { useLoad } from '../hooks/request'
import { CLIENT_LIST } from '../urls'
import CustomSelect from './common/CustomSelect'

export default function CheckFormCustom({ onSubmit, initialValues }) {
    const client = useLoad({ url: CLIENT_LIST, params: { size: 1000 } })
    const clientItem = client.response ? client.response.results : []
    const clientOptions = Object.entries(clientItem).map(([, { id, name, phoneNumber }]) => ({ id, name: id, label: `${name}-${phoneNumber}` }))

    return (
        <Formik onSubmit={onSubmit} initialValues={{ ...initialValues, client: initialValues && !isEmpty(initialValues.client) ? { label: initialValues.client.name, name: initialValues.client.id, id: initialValues.client.id } : '' }}>
            {({ submitForm, setFieldValue }) => (
                <Form className="">
                    <div className="is-flex is-justify-content-center">
                        <div className={cn(css(styles.customDiv))}>
                            <CustomSelect
                                placeholder="Пожалуйста, введите имя клиента..."
                                name="client"
                                onChange={(selectedValue) => {
                                    setFieldValue('client', selectedValue)
                                    submitForm()
                                }}
                                options={clientOptions}
                            />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

const styles = StyleSheet.create({ customDiv: { width: '100%', marginTop: -15, marginBottom: 2, marginLeft: 3, marginRight: 2, '@media (max-width: 768px)': { width: 180 } } })
