import React, { useState } from 'react'
import { StyleSheet } from 'aphrodite'
import { CLIENT_LIST } from '../../urls'
import { format } from '../../utils/number'
import { useModal } from '../../hooks/modal'
import Table from '../../components/common/Table'
import Button from '../../components/common/Button'
import ClientUpdate from '../../components/ClientUpdate'
import ClientCreate from '../../components/ClientCreate'
import { useLoad, usePutRequest } from '../../hooks/request'
import SearchFilter2 from '../../components/common/SearchFilter2'
import ClientInfo from '../../components/ClientInfo'

export default function ClientsList({ onCancel, setFieldValue, formData }) {
    const { request } = usePutRequest()
    const [params, setParams] = useState({})
    const clients = useLoad({ url: CLIENT_LIST, params }, [params])
    const list = clients.response?.results || []
    const [showCreate, hideCreate] = useModal(<ClientCreate onCancel={() => hideCreate()} reload={clients} />)
    const [showUpdate, hideUpdate] = useModal((item) => <ClientUpdate onCancel={() => hideUpdate()} item={item} clients={clients} />)
    const [showClientDetail, hideClientDetail] = useModal(
        (item) => (
            <ClientInfo
                onCancel={hideClientDetail}
                clients={clients}
                item={item} // Passing the item to ClientInfo
            />
        ),
        styles.modal
    )

    const handleChoose = async (item) => {
        setFieldValue('client', item)
        await request({ url: `main/order/${formData?.id}`, data: { client: item?.id } })
        onCancel()
    }

    return (
        <div style={{ width: '80vw', height: '80vh' }}>
            <header style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between', marginBottom: '1rem' }}>
                <SearchFilter2 setSearchParams={setParams} />
                <Button text="Добавить новый клиент" onClick={showCreate} className="is-link" icon="add-outline" />
            </header>
            <Table
                loading={clients.loading}
                columns={{ name: 'Клиент', phoneNumber: 'Телефон', phoneNumber2: 'Телефон 2', comment: 'Комментарий', sumAmount: 'Долг', actions: '' }}
                items={list}
                renderItem={(item) => (
                    <tr key={item?.id}>
                        <td>{item?.name}</td>
                        <td>{item?.phoneNumber}</td>
                        <td>{item?.phoneNumber2}</td>
                        <td>{item?.comment}</td>
                        <td>{format(item?.sumAmount)}</td>
                        <td style={{ width: 200 }}>
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <Button onClick={() => handleChoose(item)} text="Выбрать" className="is-success" />
                                <Button onClick={() => showUpdate(item)} className="is-warning" icon="create-outline" />
                                <Button onClick={() => showClientDetail(item)} text="Долг" className="is-info" />
                            </div>
                        </td>
                    </tr>
                )}
            />
        </div>
    )
}

const styles = StyleSheet.create({
    modal: {
        height: '60vh',
        width: 1200
    }
})
