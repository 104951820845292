import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import Button from '../../components/common/Button'
import { useDeleteRequest } from '../../hooks/request'
import { useModal } from '../../hooks/modal'
import ProductHistory from '../../components/ProductHistory'
import { PRODUCT_DETAIL } from '../../urls'
import { measurement } from '../../utils/position'
import PrintBarCode from '../../components/Print'
import ProductUpdate from '../../components/ProductUpdate'

export default function ItemProduct({ check, categoryList, item, user, brandList, index, products }) {
    const productRemove = useDeleteRequest({ url: PRODUCT_DETAIL.replace('{id}', item?.id) })
    const [showDetailModal, hideDetailModal] = useModal(<ProductHistory check={check} productId={item.id} user={user} item={item} hideModal={() => hideDetailModal()} />, styles.modal)
    const [showUpdateModal, setShowUpdateModal] = useModal(<ProductUpdate products={products} categoryList={categoryList} brandList={brandList} item={item} hideModal={() => setShowUpdateModal()} />, styles.modal)

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            await products.request()
        }
    }

    const componentRef = useRef()
    const handlePrint = useReactToPrint({ content: () => componentRef.current })

    return (
        <tr className=" is-size-6" key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td className="is-size-6">
                <button style={{ borderRadius: 10, border: 0 }}>
                    <i className={cn('icon ion-md-camera', css(styles.icon))} />
                </button>
            </td>
            <td onClick={() => showDetailModal()} className="is-size-6 is-capitalized">
                {item?.name}
            </td>
            <td className="is-size-6">
                {item?.count ? item?.count : 0} {measurement(item?.measurement)}
            </td>
            <td className="is-size-6">{Math.floor(1000000 + Math.random() * 9000000)}</td>
            <td className="is-size-6">{item?.category ? item.category : 'Другое'}</td>

            <td style={{ display: 'flex', alignItems: 'center' }}>
                <div className="is-pulled-right">
                    <Button onClick={handlePrint} className="mr-2 button is-link is-outlined is-small" icon="qr-code-outline" />
                    <div style={{ display: 'none' }}>
                        <PrintBarCode item={item} ref={componentRef} />
                    </div>
                    <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small" icon="pencil-outline" />
                    <Button onClick={onDelete} className="button is-danger is-outlined is-small" icon="trash" />
                </div>
            </td>
        </tr>
    )
}

const styles = StyleSheet.create({
    tr: { ':nth-child(1n) > td': { lineHeight: 1 } },
    center: { display: 'flex', alignItems: 'center' },
    td1: { display: 'flex', alignItems: 'center', gap: '5px', lineHeight: 1, cursor: 'pointer' },
    td1Img: { width: 32, height: 32, borderRadius: '50%', objectFit: 'cover' },
    td1Right: {},
    td1RightTitle: { fontFamily: 'GilroySemi', color: '#333333', lineHeight: '19px' },
    td1RightSubtitle: { marginTop: '1px', fontFamily: 'Gilroy', fontSize: '14px', color: 'rgba(51, 51, 51, 0.7)', lineHeight: '16px' },
    industries: { display: 'flex', whiteSpace: 'nowrap', flexWrap: 'wrap' },
    modal: { width: 1000 },
    icon: { fontSize: '20px', padding: 10 },
})
