import React from 'react'
import { SUPPLIER_ITEM_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import { useMessage } from '../hooks/message'
import SupplierItemForm from './SupplierItemForm'

export default function SupplierItemUpdate({
    hideModal,
    classDetail,
    item,
    supplierItems,
    groupName,
    userList,
    territory,
}) {
    const marketUpdate = usePutRequest({ url: SUPPLIER_ITEM_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await marketUpdate.request({ data })
        if (success) {
            classDetail.request()
            showMessage('Успешно изменено', 'is-success')
            supplierItems.request()
            hideModal()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Оплатить</b></h1>
            <div>
                <p className="has-text-black is-size-4">
                    Организация <span className="is-pulled-right">{groupName}</span>
                </p>
            </div>
            <br />
            <SupplierItemForm
                loading={marketUpdate.loading}
                onCancel={hideModal}
                userList={userList}
                territory={territory}
                initialValues={{ ...item }}
                onSubmit={onSubmit} />
        </div>
    )
}
