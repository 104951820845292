import React, { useState } from 'react'
import Table from '../../components/common/Table'
import { useLoad } from '../../hooks/request'
import InventoryCreateModalColumn from './inventory-create-modal-column'

export default function InventoryCreateModal() {
    const [page, setPage] = useState(1)
    const check = useLoad({ url: '/main/order' })

    const products = {
        response: {
            results: [
                { name: 'БАРАШКА 25 ВАТЕР', documentNumber: '9998', status: '12', startDate: 'шт', expirationDate: '12' },
                { name: 'БАРАШКА 25 ВАТЕР', documentNumber: '9958', status: '23', startDate: 'шт', expirationDate: '23' },
            ],
        },
    }

    return (
        <div>
            <Table
                items={products.response.results}
                columns={{ index: '№', name: 'Наименование товара', documentNumber: 'Штрих-код', status: 'Ожидаемый остаток', action: 'Посчитано' }}
                totalCount={products.response.results?.length}
                pageSize={15}
                activePage={page}
                onPageChange={setPage}
                renderItem={(item, index) => <InventoryCreateModalColumn index={index} products={products.response.results} check={check} key={item.id} item={item} />}
            />
        </div>
    )
}
