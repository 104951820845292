import React from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Button from './common/Button'
import { SEND_MESSAGE } from '../urls'
import { useGetRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { getDateOtherFormat } from '../utils/date'
import ClientUpdate from './ClientUpdate'
import { useMessage } from '../hooks/message'

export default function BorrowItem({
    item, clients,
    // onCheckboxChange,
    index
}) {
    const sendMessage = useGetRequest({ url: SEND_MESSAGE.replace('{clientId}', item.id) })
    const [showMessage] = useMessage()

    async function onSend() {
        const { success } = await sendMessage.request()
        if (success) {
            showMessage(`Сизнинг sms хабарингиз ${item.name} га муваффақиятли юборилди`, 'is-success')
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(<ClientUpdate clients={clients} item={item} onCancel={() => hideUpdateModal()} />)

    const currentDate = new Date()

    // const [isChecked, setIsChecked] = useState(false)

    /*   const handleCheckboxChange = () => {
           const newCheckedState = !isChecked
           setIsChecked(newCheckedState)
           onCheckboxChange(item.id, newCheckedState)
       } */

    const sumDifference = item.sumAmountIn - item.sumAmountOut
    const usdDifference = item.usdAmountIn - item.usdAmountOut

    function format(value) {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        })
            .format(value)
            .replace(/,/g, ' ')
    }
    return (
        <tr className={`${getDateOtherFormat(currentDate) > item.term ? 'has-text-danger is-size-6' : 'has-text-black is-size-6'}`}
            key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td className="is-size-6">
                <Link to={`/borrow/${item.id}`}
                    className={cn('', `${item.sumAmount > 0 ? 'has-text-link' : 'has-text-link'}`)}>
                    {item.name}
                </Link>
            </td>
            <td className="is-size-6">{item.phoneNumber}</td>
            <td>{item.description}</td>

            <td className="is-size-6 has-text-danger">
                {`${format(sumDifference || 0)} Сум`}
                <br /> {usdDifference ? `${format(usdDifference)} USD` : null}
            </td>
            <td className="has-text-left">
                <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small"
                    icon="pencil-outline" />

                <Button loading={sendMessage.loading} onClick={onSend} className="button is-link is-outlined is-small"
                    icon="paper-plane-outline" />
            </td>
        </tr>
    )
}
