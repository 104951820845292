import React, { useEffect } from 'react'
import { Field, useFormikContext } from 'formik'
import cn from 'classnames'
import ValidationErrorMessage from './ValidationErrorMessage'
import { usePutRequest } from '../../hooks/request'

export default function TradeTypeSelect({ name, className, label, validate, options = [], optionValue = 'id', optionLabel = 'name', loading = false, empty = false, optional = false, help, ...attributes }) {
    const { setFieldValue, values, errors, touched } = useFormikContext()
    const value = values[name]
    const error = errors[name]
    const touch = touched[name]
    const { request } = usePutRequest()

    useEffect(() => {
        const fn = async () => {
            const payload = { tradeType: values.tradeType }
            await request({ url: `main/order/${values.id}`, data: payload })
        }

        fn()
    }, [value, values.tradeType])

    useEffect(() => {
        setFieldValue(name, values.tradeType)
    }, [options])

    return (
        <div className="field">
            <div className="control">
                {label ? (
                    <label htmlFor={name}>
                        {label} &nbsp;
                        {optional ? <span className="form-hint">shart emas</span> : null}
                    </label>
                ) : null}

                <div className={cn('select is-fullwidth', className, { 'is-loading': loading })}>
                    <Field name={name} id={name} component="select" validate={validate} {...attributes}>
                        {empty ? <option value="" /> : null}

                        {options.map((item) => (
                            <option value={item[optionValue]} key={item[optionValue]}>
                                {item[optionLabel]}
                            </option>
                        ))}
                    </Field>
                </div>

                <ValidationErrorMessage name={name} />
            </div>

            {help && (!error || !touch) ? <p className="form-hint">{help}</p> : null}
        </div>
    )
}
