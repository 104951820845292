import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import Layout from '../../components/Layout'
import CashRegisterForm from './CashRegisterForm'
import 'react-tabs/style/react-tabs.css'
import { useLoad, usePostRequest } from '../../hooks/request'
import { ORDER_DETAIL, ORDER_LIST } from '../../urls'
import { useModal } from '../../hooks/modal'
import DeleteModal from '../../components/common/DeleteModal'

export default function CashRegister() {
    const { request } = usePostRequest()
    const cash = useLoad({ url: ORDER_LIST })
    const navigate = useHistory()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const orders = cash?.response ? cash?.response.results : []
    const [tabs, setTabs] = useState([])
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const [showDelete, hideDelete] = useModal((item) => <DeleteModal onCancel={() => hideDelete()} url={ORDER_DETAIL.replace('{id}', item?.id)} name={item?.name} refresh={cash.request} />)

    useEffect(() => {
        const transformedOrders = orders?.map((order) => ({ id: order.id, name: new Date(order.createdAt).toTimeString().slice(0, 5), formState: { ...order, id: order.id } }))
        setTabs(transformedOrders)

        const savedTabIndex = localStorage.getItem('activeTabIndex')
        if (savedTabIndex) setActiveTabIndex(parseInt(savedTabIndex, 10))
    }, [orders])

    const addTab = async () => {
        const { response } = await request({
            url: '/main/order',
            data: {
                id: Math.floor(100000 + Math.random() * 900000),
                name: new Date().toTimeString().slice(0, 5),
                formState: {},
                items: [],
            },
        })

        setTabs((prev) => {
            const updatedTabs = [{ ...response, name: new Date(response.createdAt).toTimeString().slice(0, 5), id: response.id }, ...prev]
            setActiveTabIndex(0)
            localStorage.setItem('activeTabIndex', 0)
            return updatedTabs
        })

        const existingOrders = JSON.parse(localStorage.getItem('orders')) || []
        localStorage.setItem(
            'orders',
            JSON.stringify([
                ...existingOrders,
                {
                    ...response,
                    name: new Date(response.createdAt).toTimeString().slice(0, 5),
                    products: [],
                    id: response.id,
                },
            ])
        )
        navigate.go(0)
    }

    useEffect(() => {
        if (!tabs) return

        if (tabs.length) {
            const localOrders = localStorage.getItem('orders') ? JSON.parse(localStorage.getItem('orders')) : []
            const filteredOrders = localOrders.filter((order) => tabs.find((tab) => tab.id === order.id))
            localStorage.setItem('orders', JSON.stringify(filteredOrders))
        }
    }, [tabs])

    const removeTab = async (data) => showDelete(data)

    const updateFormState = (id, newState) => setTabs((prev) => prev.map((tab) => (tab.id === id ? { ...tab, formState: newState } : tab)))

    const handleTabChange = (index) => {
        setActiveTabIndex(index)
        localStorage.setItem('activeTabIndex', index)
    }

    return (
        <Layout>
            <Tabs selectedIndex={activeTabIndex} onSelect={handleTabChange}>
                <TabList>
                    {tabs?.map((tab, i) => (
                        <Tab key={tab.id}>
                            <span className="tag is-info mr-2">{i + 1}</span>
                            {tab.name}

                            {tabs?.length > 1 && <button onClick={() => removeTab(tab)} className="delete ml-2 has-background-danger" aria-label="delete" />}
                        </Tab>
                    ))}

                    {tabs?.length < 5 && <ion-icon onClick={() => addTab()} name="add-outline" style={{ fontSize: '16px', color: 'white', borderRadius: '4px', backgroundColor: '#3e8ed0', height: '2rem', width: '2rem', verticalAlign: 'top', marginLeft: '0.5rem', cursor: 'pointer' }} />}
                </TabList>

                {tabs?.map((tab) => (
                    <TabPanel key={tab.id}>
                        <CashRegisterForm cash={cash} formData={tab.formState} updateFormData={(newState) => updateFormState(tab.id, newState)} />
                    </TabPanel>
                ))}
            </Tabs>
        </Layout>
    )
}
