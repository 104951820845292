import React from 'react'
import { Form, Formik } from 'formik'

import Input from '../../components/common/Input'
import { required } from '../../utils/validators'
import Button from '../../components/common/Button'

export default function ExpenseForm({ onCancel, onSubmit, loading, initialValues }) {
    return (
        <Formik onSubmit={onSubmit} initialValues={{ cashRegister: '', cashier: '', flowType: '', note: '', amount: '', ...initialValues }}>
            <Form>
                <div className="columns">
                    <div className="column">
                        <Input name="cashRegister" label="Касса" validate={required} disabled={loading} />
                        <Input name="cashier" label="Кассир" validate={required} disabled={loading} />
                        <Input name="flowType" label="Тип расхода" validate={required} disabled={loading} />
                        <Input name="amount" type="number" step="0.01" label="Цена поступление" validate={required} disabled={loading} />
                        <Input component="textarea" name="note" label="Примечание" validate={required} disabled={loading} />
                    </div>
                </div>

                <div className="is-pulled-right">
                    <Button onClick={onCancel} icon="close-outline" text="Закрыть" className="is-danger" disabled={loading} />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сохранить" icon="add-outline" type="submit" className="is-success" disabled={loading} />
                </div>
            </Form>
        </Formik>
    )
}
