import React from 'react'
import { usePutRequest } from '../hooks/request'
import { CURRENCY_DETAIL } from '../urls'
import { useMessage } from '../hooks/message'
import CurrencyForm from './CurrencyForm'

export default function CurrencyUpdate({ onCancel, onSuccess, item }) {
    const brandUpdate = usePutRequest({ url: CURRENCY_DETAIL.replace('{id}', item.id) })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await brandUpdate.request({ data })

        if (success) {
            showMessage('Успешно изменена', 'is-success')
            onSuccess()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Изменить бренд</b></h1>
            <CurrencyForm
                loading={brandUpdate.loading}
                initialValues={item}
                onSubmit={onSubmit}
                onCancel={onCancel} />
        </div>
    )
}
