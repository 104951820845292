import React from 'react'
import { Form, Formik } from 'formik'
import Input from '../../components/common/Input'
import Select from '../../components/common/Select'
import { required } from '../../utils/validators'
import Button from '../../components/common/Button'

export default function InventoryForm({ onCancel, onSubmit, loading, initialValues }) {
    const handleSubmit = (vals, actions) => {
        const data = { ...vals, name: vals.name || undefined, price: vals.price || undefined, expenseType: vals.expenseType || undefined, note: vals.note || undefined }
        onSubmit(data, actions)
    }

    const markets = [{ name: 'БЕК СТРОЙ МАРКЕТ БОБИР +998999693505', value: 'bek-stroy-market' }]

    const categories = [
        { name: 'Канцтовары', value: 'Канцтовары' },
        { name: 'Болты', value: 'Болты' },
    ]

    return (
        <Formik onSubmit={handleSubmit} initialValues={{ name: '', inventory: '', category: '', note: '', ...initialValues }}>
            <Form>
                <div className="columns">
                    <div className="column">
                        <Select optionValue="value" optionLabel="value" name="name" label="Торговая точка*" options={markets} disabled={loading} />
                        <Input name="inventory" label="Инвентаризация №" validate={required} disabled={loading} />
                        <Select optionValue="value" optionLabel="value" name="category" label="Категория" options={categories} disabled={loading} />
                        <Input name="note" label="Примечание" disabled={loading} />
                    </div>
                </div>

                <div className="is-pulled-right">
                    <Button onClick={onCancel} icon="close-outline" text="Oтмена " className="is-danger" disabled={loading} />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сохранить" icon="add-outline" type="submit" className="is-success" disabled={loading} />
                </div>
            </Form>
        </Formik>
    )
}
