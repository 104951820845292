import React from 'react'
import Layout from '../../components/Layout'
import SettingsTabs from '../../components/settings/SettingsTabs'
import Card from '../../components/common/Card'
import Button from '../../components/common/Button'
import Table from '../../components/common/Table'
import CurrencyCreate from '../../components/CurrencyCreate'
import { useModal } from '../../hooks/modal'
import { useLoad } from '../../hooks/request'
import CurrencyItem from '../../components/CurrencyItem'
import { CURRENCY_LIST } from '../../urls'

export default function Currency() {
    const currency = useLoad({ url: CURRENCY_LIST })

    const [showCreateModal, hideCreateModal] = useModal(
        <CurrencyCreate onSuccess={() => {
            hideCreateModal()
            currency.request()
        }} onCancel={() => hideCreateModal()} />,
    )
    return (
        <Layout className="has-background-light">
            <SettingsTabs />

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
                <Card>
                    <div className="columns">
                        <div className="is-size-4 column">Доллар курс</div>

                        <div className="column is-narrow">
                            <Button
                                onClick={showCreateModal}
                                text="Добавить"
                                icon="add-outline"
                                className="is-pulled-right is-link is-outlined" />
                        </div>
                    </div>

                    <Table
                        loading={currency.loading}
                        items={currency.response ? currency.response : []}
                        renderItem={(item, index) => (
                            <CurrencyItem
                                index={index}
                                currency={currency}
                                item={item}
                                key={item.id}
                                onDelete={currency.request}
                                onUpdate={currency.request}
                                status={currency}
                            />
                        )} />

                </Card> <br />
            </div>
        </Layout>
    )
}
