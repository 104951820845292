import React from 'react'
import Select from 'react-select'

export default function NewSupplierSelect({ name, defaultValue, value, options = [], optionValueKey = 'id', optionLabelKey = 'name', ...props }) {
    return (
        <Select
            defaultValue={defaultValue}
            value={value}
            options={options}
            getOptionValue={(opt) => opt[optionValueKey]}
            getOptionLabel={(opt) => opt[optionLabelKey]}
            isClearable
            styles={{ container: (base) => ({ ...base, minWidth: '15rem' }), control: (base) => ({ ...base, height: '40px' }), placeholder: (base) => ({ ...base, whiteSpace: 'nowrap' }), indicatorSeparator: (base) => ({ ...base, display: 'none' }) }}
            {...props}
            isMulti={false}
        />
    )
}
