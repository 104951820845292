import React from 'react'
import { CURRENCY_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import Loader from './common/Loader'
import CurrencyUpdate from './CurrencyUpdate'
import { getDateTime } from '../utils/date'
import { format } from '../utils/number'

export default function CurrencyItem({ item, currency, index }) {
    const brandRemove = useDeleteRequest({ url: CURRENCY_DETAIL.replace('{id}', item.id) })
    const [showUpdateModal, setShowUpdateModal] = useModal(
        <CurrencyUpdate
            item={item}
            onSuccess={() => {
                currency.request()
                setShowUpdateModal()
            }}
            onCancel={() => setShowUpdateModal()}
        />,
    )

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await brandRemove.request()
            currency.request()
        }
    }

    return (
        <tr>
            <td>
                {index + 1}
            </td>
            <td>
                {format(item.code)}Сум
            </td>

            <td>
                {getDateTime(item.createdAt)}
            </td>
            <td>
                {getDateTime(item.updatedAt)}
            </td>

            <td>
                <div className="is-pulled-right">
                    {!brandRemove.loading ? (
                        <i onClick={onDelete} className="icon pointer ion-md-trash" />
                    ) : <Loader className="icon" />}

                    <i onClick={showUpdateModal} className="icon pointer ion-md-create" />

                </div>
            </td>
        </tr>
    )
}
