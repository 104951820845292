import React, { createContext, useState } from 'react'
import Message from './Message'
import Modal from './Modal'
import CustomModal from './CustomModal'
import RightModal from './RightModal'

export const Context = createContext()

export default function BaseContextWrapper({ children }) {
    const [text, setText] = useState()
    const [className, setClassName] = useState('')
    const [modalComponent, setModalComponent] = useState()
    const [customModalComponent, setCustomModalComponent] = useState()
    const [rightModalComponent, setRightModalComponent] = useState()

    return (
        <Context.Provider value={{ setText, setClassName, setModalComponent, setCustomModalComponent, setRightModalComponent }}>
            {children}

            {text ? <Message text={text} className={className} closeMessage={() => setText(null)} /> : null}

            {modalComponent ? (
                <Modal isActive style={modalComponent.styles} onClose={() => setModalComponent(null)}>
                    {modalComponent.content}
                </Modal>
            ) : null}

            {customModalComponent ? (
                <CustomModal isActive style={customModalComponent.styles} onClose={() => setCustomModalComponent(null)}>
                    {customModalComponent.content}
                </CustomModal>
            ) : null}

            {rightModalComponent ? (
                <RightModal isActive helper={rightModalComponent.helper} onClose={() => setRightModalComponent(null)}>
                    {rightModalComponent.content}
                </RightModal>
            ) : null}
        </Context.Provider>
    )
}
