import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import queryString from 'querystring'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Layout from '../../components/Layout'
import Search from '../../components/Search'
import { useModal } from '../../hooks/modal'
import { format } from '../../utils/number'
import empty from '../../static/empty-shop.png'
import { useMessage } from '../../hooks/message'
import Table from '../../components/common/Table'
import Button from '../../components/common/Button'
import CheckCreate from '../../components/CheckCreate'
import { useQueryParams } from '../../hooks/queryString'
import ProductCreate from '../../components/ProductCreate'
import SummaryCard from '../../components/common/SummaryCard'
import SupplierSelect from '../../components/common/supplierSelect'
import NomenclatureHomeItem from '../../components/NomenclatureHomeItem'
import { useGetRequest, useLoad, usePostRequest } from '../../hooks/request'
import { domain, NOMENCLATURE_LIST, PRODUCT_EXPORT, SUPPLIER_LIST, UPLOAD_FILE } from '../../urls'

export default function Nomenclature() {
    const params = useQueryParams()
    const [page, setPage] = useState(1)
    const products = useLoad({ url: NOMENCLATURE_LIST, params: { page, ...params, status: 'active' } }, [params, page])
    const productList = products?.response ? products?.response?.results : []
    const check = useLoad({ url: '/main/order' })
    const suppliers = useLoad({ url: SUPPLIER_LIST, params: { ...params } })
    const [showCreateModal, setShowCreateModal] = useModal(<ProductCreate reload={products} onCancel={() => setShowCreateModal()} />, styles.modal)
    const [showCreateCheck, hideCreateCheck] = useModal(<CheckCreate reload={check} onCancel={() => hideCreateCheck()} />)

    const navigate = useHistory()
    const [showMessage] = useMessage()

    const { request: postExcel } = usePostRequest({ url: UPLOAD_FILE })
    const handleProductImport = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        const { success, error } = await postExcel({ data: formData })
        if (success) {
            showMessage('Файл успешно отправлен', 'is-success')
            navigate.push('?')
        }
        if (error) showMessage(error?.data?.detail ?? `Error: ${error?.status} ${error?.statusText}`, 'is-danger')
    }

    const excelProduct = useGetRequest({ url: PRODUCT_EXPORT })
    const excelItemProduct = excelProduct.response ? excelProduct.response.fileUrl || excelProduct.response.file : null

    useEffect(() => {
        if (excelItemProduct && !isEmpty(excelItemProduct)) {
            const a = document.createElement('a')
            a.href = `${domain}${excelItemProduct}`
            a.setAttribute('file', 'response')
            a.click()
        }
    }, [excelItemProduct])

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        navigate.push(`?${queryString.stringify({ ...params, search })}`)
    }

    return (
        <Layout showCreateModal={showCreateModal} showCreateCheck={showCreateCheck}>
            <div className="mb-3 is-flex">
                <Search onSearch={onSearch} />
                <SupplierSelect name="supplier" className="ml-2" options={suppliers?.response?.results} placeholder="Выбор поставщика" />
                <Button onClick={excelProduct.request} className="button is-link is-outlined ml-2" text="Экспорт" />

                <label style={{ position: 'relative' }}>
                    <Button className="button is-link is-outlined ml-2" text="Импорт" />
                    <input onChange={handleProductImport} value="" type="file" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                </label>
            </div>

            <hgroup style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(18rem, 1fr))', gap: '1rem', marginBottom: '2rem' }}>
                <SummaryCard label="Общая сумма покупки:" value={`${format(products.response?.totalSaleSum || 0)} Cум`} />
                <SummaryCard label="Общая сумма продажи:" value={`${format(products.response?.totalBuySum || 0)} Cум`} />
                <SummaryCard label="Общая прибыль:" value={`${format(products.response?.totalProfit || 0)} Cум`} />
                <SummaryCard label="Все продуктов количестве:" value={`${format(products.response?.totalProductsCount || 0)}`} />
            </hgroup>

            <div className="mt-4">
                {products.response && products.response.count === 0 ? (
                    <div className="is-flex is-justify-content-center is-align-items-center hei">
                        <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                    </div>
                ) : (
                    <Table
                        className="table is-striped is-fullwidth is-bordered"
                        loading={products.loading}
                        items={productList}
                        columns={{
                            id: '№',
                            productName: 'Наименование товара',
                            provider: 'Поставщик',
                            barcode: 'Штрих-код',
                            priceBuy: 'Цена поступление',
                            priceSale: 'Цена за единицу',
                            transferPricer: 'Оптом цена',
                            currency: 'Валюта',
                            count: 'Количество',
                            actions: 'Действие'
                        }}
                        totalCount={products.response ? products.response.count : 0}
                        pageSize={15}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item, index) => <NomenclatureHomeItem index={index} products={products} check={check} key={item.id} item={item} />}
                    />
                )}
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: { marginTop: '2rem' },
    emptyImg: { width: '330px', height: '300px' },
    loader: { marginTop: '2rem' },
    modal: { width: 800 },
    checkmark: { marginRight: '3px' },
})
