import cn from 'classnames'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { useHistory, useParams } from 'react-router-dom'

import { format } from '../utils/number'
import { useModal } from '../hooks/modal'
import Layout from '../components/Layout'
import empty from '../static/empty-shop.png'
import { useMessage } from '../hooks/message'
import Button from '../components/common/Button'
import CheckCreate from '../components/CheckCreate'
import NewTable from '../components/common/NewTable'
import { useQueryParams } from '../hooks/queryString'
import SupplierTabs from '../components/SupplierTabs'
import ProductCreate from '../components/ProductCreate'
import ProductHomeItem from '../components/ProductHomeItem'
import SearchFilter from '../components/common/SearchFIlter'
import { useGetRequest, useLoad, usePostRequest } from '../hooks/request'
import { DOCUMENTS_PRODUCTS_LIST, domain, PRODUCT_EXPORT, SUPPLIER_DETAIL, UPLOAD_FILE } from '../urls'
import SummaryCard from '../components/common/SummaryCard'

export default function SupplierProductsDetail() {
    const params = useQueryParams()
    const { supplierId } = useParams()
    const products = useLoad({ url: DOCUMENTS_PRODUCTS_LIST, params: { ...params, status: 'active', supplier: supplierId } })
    const classDetail = useLoad({ url: SUPPLIER_DETAIL.replace('{id}', supplierId) })
    const className = classDetail.response

    const productList = products.response ? products.response.results : []
    const check = useLoad({ url: '/main/order' })

    const [showCreateModal, setShowCreateModal] = useModal(<ProductCreate reload={products} onCancel={() => setShowCreateModal()} />, styles.modal)
    const [showCreateCheck, hideCreateCheck] = useModal(<CheckCreate reload={check} onCancel={() => hideCreateCheck()} />)

    const [showMessage] = useMessage()
    const history = useHistory()

    const { request: postExcel } = usePostRequest({ url: UPLOAD_FILE })
    const handleProductImport = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        const { success, error } = await postExcel({ data: formData })
        if (success) {
            showMessage('Файл успешно отправлен', 'is-success')
            history.push('?')
        }
        if (error) showMessage(error?.data?.detail ?? `Error: ${error?.status} ${error?.statusText}`, 'is-danger')
    }

    const excelProduct = useGetRequest({ url: PRODUCT_EXPORT })
    const excelItemProduct = excelProduct.response ? excelProduct.response.fileUrl || excelProduct.response.file : null

    useEffect(() => {
        if (excelItemProduct && !isEmpty(excelItemProduct)) {
            const a = document.createElement('a')
            a.href = `${domain}${excelItemProduct}`
            a.setAttribute('file', 'response')
            a.click()
        }
    }, [excelItemProduct])

    return (
        <Layout showCreateModal={showCreateModal} showCreateCheck={showCreateCheck}>
            <SupplierTabs supplierId={supplierId} name={className ? className.name : ''} />
            <div className="is-flex mb-3">
                <SearchFilter style={{ width: '400px' }} />

                <Button onClick={excelProduct.request} icon="cloud-upload-outline" className="button is-link is-outlined ml-2 mr-2" text="Экспорт" />

                <label style={{ position: 'relative' }}>
                    <Button icon="cloud-download-outline" className="button is-link is-outlined ml-2 mr-2" text="Импорт" />
                    <input onChange={handleProductImport} value="" type="file" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                </label>

                <Button text="Добавить" icon="add-outline" className="button is-link is-outlined ml-2" onClick={showCreateModal} />
            </div>

            {!products.loading ? (
                <>
                    <hr />
                    <hgroup style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(18rem, 1fr))', gap: '1rem', marginBottom: '2rem' }}>
                        <SummaryCard label="Общая сумма покупки:" value={`${format(products?.response?.totalSaleSum ? products?.response?.totalSaleSum : 0)} сум`} />
                        <SummaryCard label="Общая сумма продажи:" value={`${format(products?.response?.totalBuySum ? products?.response?.totalBuySum : 0)} сум`} />
                        <SummaryCard label="Общая прибыль:" value={`${format((products?.response?.totalSaleSum ? products?.response?.totalSaleSum : 0) - (products?.response?.totalBuySum ? products?.response?.totalBuySum : 0))} сум`} />
                        <SummaryCard label="Все продуктов количестве:" value={`${products?.response?.totalProductsCount ? products?.response?.totalProductsCount : 0}`} />
                    </hgroup>
                </>
            ) : null}

            <div className="mt-4">
                {products.response && products.response.count === 0 ? (
                    <div className="is-flex is-justify-content-center is-align-items-center hei">
                        <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                    </div>
                ) : (
                    <NewTable
                        loading={products.loading}
                        items={productList}
                        columns={{ index: '', name: 'Название', count: 'Количество', priceSale: 'Цена продажи', priceBuy: 'Цена покупки', transferPricer: 'Цена Трансфер', measurement: 'Измерение', createdAt: 'Дата', actions: '' }}
                        totalCount={products.response ? products.response.count : 0}
                        pageSize={15}
                        renderItem={(item, index) => <ProductHomeItem index={index} products={products} check={check} key={item.id} item={item} />}
                    />
                )}
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({ space: { marginTop: '2rem' }, emptyImg: { width: '330px', height: '300px' }, loader: { marginTop: '2rem' }, modal: { width: 800 }, checkmark: { marginRight: '3px' } })
