import React from 'react'
import { NavLink } from 'react-router-dom'

import Button from './common/Button'
import { CLIENT_DETAIL } from '../urls'
import { format } from '../utils/number'
import { useModal } from '../hooks/modal'
import ClientUpdate from './ClientUpdate'
import { getDateTime } from '../utils/date'
import { useDeleteRequest } from '../hooks/request'

export default function BonusTableItem({ item, index, clients }) {
    const productRemove = useDeleteRequest({ url: CLIENT_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            clients.request()
        }
    }

    const [showUpdateModal, hideUpdateModal] = useModal(<ClientUpdate clients={clients} item={item} onCancel={() => hideUpdateModal()} />)

    return (
        <tr className="is-size-6">
            <td>{index + 1}</td>
            <td>
                <NavLink to={`/client/${item.id}/order`}>{item?.name}</NavLink>
            </td>
            <td>{item?.phoneNumber}</td>
            <td>{format(item.totalOrderSum ? item.totalOrderSum : '0')} Сум</td>
            <td>{getDateTime(item.createdAt)}</td>

            <td className="has-text-right">
                <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small" icon="pencil-outline" />
                <Button onClick={onDelete} className="button is-danger mr-2 is-outlined is-small" icon="trash" />
            </td>
        </tr>
    )
}
