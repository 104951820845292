import React from 'react'
import { useHistory } from 'react-router-dom'
import { usePostRequest } from '../hooks/request'
import { DOCUMENTS_LIST } from '../urls'
import { useMessage } from '../hooks/message'
import Layout from './Layout'
import DocumentsForm from './DocumentsForm'

export default function DocumentsCreate({ userList, territory }) {
    const market = usePostRequest({ url: DOCUMENTS_LIST })
    const [showMessage] = useMessage()
    const history = useHistory()
    async function onSubmit(data) {
        const { success } = await market.request({ data })

        if (success) {
            showMessage('Успешно сохранен', 'is-success')
            history.push('/documents-in')
            localStorage.removeItem('items')
            localStorage.removeItem('formData')
        }
    }

    return (
        <Layout>
            <h3 className="title has-text-left">
                <b>Прием товаров Создать</b>
            </h3>

            <DocumentsForm loading={market.loading} userList={userList} onSubmit={onSubmit} territory={territory} />
        </Layout>
    )
}
