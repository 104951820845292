import React from 'react'
import { useMessage } from '../../hooks/message'
import { usePostRequest } from '../../hooks/request'
import { PRODUCT_LIST } from '../../urls'
import PromotionForm from './promotion-create-form'

export default function PromotionCreate({ onCancel, categoryList, reload, brandList }) {
    const products = usePostRequest({ url: PRODUCT_LIST })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success, error } = await products.request({ data })
        if (success) {
            reload.request()
            onCancel()
            showMessage('Успешно добавлен', 'is-success')
        }
        if (error) showMessage(error?.data?.detail || 'Ошибка', 'is-danger')
    }

    return (
        <div>
            <h1 className="title has-text-centered">
                <b>Добавить товар</b>
            </h1>

            <PromotionForm loading={products.loading} categoryList={categoryList} brandList={brandList} onCancel={onCancel} onSubmit={onSubmit} />
        </div>
    )
}
