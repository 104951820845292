import React from 'react'
import Button from '../../components/common/Button'

export default function InventoryCreateModalColumn({ item, index }) {
    return (
        <tr className="is-size-6" key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                {item.name}
            </td>
            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                {item.documentNumber}
            </td>
            <td className="is-size-6" style={{ textWrap: 'nowrap' }}>
                <span className="has-text-success">{item.status}</span>
            </td>
            <td className="is-size-6" style={{ textWrap: 'nowrap', display: 'flex', gap: 12 }}>
                <span className="has-text-success">{item.expirationDate}</span>
                <Button text="-" className="mr-2 button is-info is-danger is-outlined is-small" />
            </td>
        </tr>
    )
}
