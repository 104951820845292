import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Layout from '../components/Layout'
import Card from '../components/common/Card'
import { format } from '../utils/number'
import { useLoad } from '../hooks/request'
import { DOCUMENTS_DETAIL, DOCUMENTS_PRODUCTS_LIST } from '../urls'
import { getCurrency } from '../utils/types'
import DocumentsPreviewItem from '../components/DocumentsPreviewItem'
import Table from '../components/common/Table'
import { useQueryParams } from '../hooks/queryString'

export default function DocumentsPreview() {
    const { documentId } = useParams()
    const { params } = useQueryParams()
    const documentDetail = useLoad({ url: DOCUMENTS_DETAIL.replace('{id}', documentId) })
    const [page, setPage] = useState(1)
    const docProducts = useLoad({
        url: DOCUMENTS_PRODUCTS_LIST,
        params: { documents: documentId, ...params, page }
    }, [page])
    const docProductsItem = docProducts.response ? docProducts.response.results : []

    return (
        <Layout>
            <header className="is-flex is-justify-content-space-between mb-2" />

            <div className="mb-2">
                <Card>

                    <div className="is-pulled-left mt-2 is-flex">
                        <Link className="is-white is-link is-outlined mr-2" to="/documents-in">
                            <i className="icon ion-md-arrow-back is-size-4" />
                        </Link>
                        <p>Поставщик: <strong>{documentDetail.response ? documentDetail.response.supplierSelect.name : ''}</strong>
                        </p>
                    </div>

                    <div className="is-pulled-right mb-5">
                        <span className="tag is-large is-light mr-2">
                            Сумма прихода: {format(documentDetail.response ? documentDetail.response.incomeAmount : '')}
                            {documentDetail.response && documentDetail.response.currency
                                ? getCurrency(documentDetail.response.currency) : ''}
                        </span>
                        <span className="tag is-large is-light mr-2">
                            Сумма продаж: {format(documentDetail.response ? documentDetail.response.salesAmount : '')}

                            {documentDetail.response && documentDetail.response.currency
                                ? getCurrency(documentDetail.response.currency) : ''}
                        </span>
                        <span className="tag is-large is-light">
                            Доход: {format(documentDetail.response ? documentDetail.response.income : '')}
                            {documentDetail.response && documentDetail.response.currency
                                ? getCurrency(documentDetail.response.currency) : ''}

                        </span>
                    </div>
                </Card>
            </div>

            <Card>
                <Table
                    onPageChange={setPage}
                    activePage={page}
                    showEmptyMessage={false}
                    columns={{
                        name: 'Наименование товара',
                        code: 'Штрих-код',
                        quantity: 'Количество',
                        price: 'Цена поступление\t',
                        price_transfer: 'Оптом цена\t',
                        price_sale: 'Цена за единицу\t',
                        summ: 'Сумма',
                        actions: '',

                    }}
                    pagination
                    totalCount={docProducts.response ? docProducts.response.count : 0}
                    loading={docProducts.loading}
                    items={docProductsItem}
                    renderItem={(item) => {
                        const quantity = typeof item?.count === 'number' ? item.count : 0
                        const price = typeof item?.price === 'number' ? item.price : 0
                        const sum = format(quantity * price)
                        return (
                            <DocumentsPreviewItem
                                key={item?.id}
                                documentDetail={documentDetail}
                                docProducts={docProducts}
                                sum={sum}
                                item={item} />
                        )
                    }}
                />
            </Card>
        </Layout>
    )
}
