import cn from 'classnames'
import React from 'react'
import { NavLink, useRouteMatch } from 'react-router-dom'

export default function SupplierTabs({ name, supplierId }) {
    const { path } = useRouteMatch()

    return (
        <div className="tabs">
            <ul>
                <li className={cn({ 'is-active': path.startsWith(`/supplier/${supplierId}/checks`) })}>
                    <NavLink to={`/supplier/${supplierId}/checks`}>Поставщики : ({name})</NavLink>
                </li>

                <li className={cn({ 'is-active': path.startsWith(`/supplier/${supplierId}/products`) })}>
                    <NavLink to={`/supplier/${supplierId}/products`}>Продукты : ({name})</NavLink>
                </li>
            </ul>
        </div>
    )
}
