import React from 'react'
import { useDeleteRequest } from '../../hooks/request'
import Button from './Button'
import { useMessage } from '../../hooks/message'

export default function DeleteModal({ url, onCancel = () => {}, refresh = () => {}, name = '' }) {
    const [showMessage] = useMessage()
    const { request, loading } = useDeleteRequest()
    const handleClick = async () => {
        const { success, error } = await request({ url })
        if (success) {
            refresh()
            onCancel()
            showMessage('Успешно удалено', 'is-success')
        }
        if (error) {
            showMessage(error?.data?.detail || 'Ошибка', 'is-danger')
        }
    }
    return (
        <main>
            <h4 className="is-size-4">
                Вы уверены, что хотите удалить <b>{name}</b> из базы данных?
                <strong className="is-size-4"> Это необратимый процесс!!!</strong>
            </h4>

            <div className="mt-4 is-flex is-justify-content-flex-end">
                <Button onClick={handleClick} loading={loading} text="Удалить" icon="trash-outline" className="is-danger" disabled={loading} /> &nbsp;
                <Button onClick={onCancel} icon="close-outline" text="Отмена" className="is-outlined" disabled={loading} />
            </div>
        </main>
    )
}
