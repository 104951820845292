import React from 'react'
import { Form, Formik } from 'formik'
import Input from '../../components/common/Input'
import Select from '../../components/common/Select'
import { required } from '../../utils/validators'
import Button from '../../components/common/Button'

export default function PromotionForm({ onCancel, onSubmit, loading, initialValues }) {
    const handleSubmit = (vals, actions) => {
        const data = { ...vals, name: vals.name || undefined, price: vals.price || undefined, expenseType: vals.expenseType || undefined, note: vals.note || undefined }
        onSubmit(data, actions)
    }

    const markets = [{ name: 'БЕК СТРОЙ МАРКЕТ БОБИР +998999693505', value: 'bek-stroy-market' }]

    const products = [
        { name: 'УТЮК ВАССЕРМАН W008', value: 'W008' },
        { name: 'СВЕРЛО АРЧА 40/40', value: 'СВЕРЛО' },
    ]

    return (
        <Formik onSubmit={handleSubmit} initialValues={{ name: '', price: '', market: '', product: '', quantity_1: '', quantity_2: '', ...initialValues }}>
            <Form>
                <div className="columns">
                    <div className="column">
                        <Select optionValue="value" optionLabel="value" name="market" label="Торговая точка*" options={markets} disabled={loading} />
                        <Input name="name" label="Имя*" validate={required} disabled={loading} />
                        <Select optionValue="value" optionLabel="value" name="product" label="Продукт*" options={products} disabled={loading} />

                        <Input name="quantity_1" type="number" label="Количество*" validate={required} disabled={loading} />
                        <Select optionValue="value" optionLabel="value" name="product" label="Продукт*" options={products} disabled={loading} />
                        <Input name="quantity_2" type="number" label="Количество*" validate={required} disabled={loading} />
                    </div>
                </div>

                <div className="is-pulled-right">
                    <Button onClick={onCancel} icon="close-outline" text="Oтмена " className="is-danger" disabled={loading} />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сохранить" icon="add-outline" type="submit" className="is-success" disabled={loading} />
                </div>
            </Form>
        </Formik>
    )
}
