import React from 'react'
import moment from 'moment'
import cn from 'classnames'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

import { format } from '../utils/number'
import { DATETIME_FORMAT } from '../utils/date'
import { getMeasurement } from '../utils/types'
import Button from '../components/common/Button'

export default function ArchiveOrderInfo({ item, onCancel }) {
    return (
        <>
            <main style={{ marginBottom: 100 }}>
                {item?.client && (
                    <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                        <b>Клиент:</b>
                        <span style={{ fontWeight: 500 }}>{item.client?.name}</span>
                    </p>
                )}
                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                    <b>Дата:</b>
                    <span style={{ fontWeight: 500 }}>{moment(item?.createdAt).format(DATETIME_FORMAT)}</span>
                </p>
                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                    <b>ID-чека:</b>
                    <span style={{ fontWeight: 500 }}>{item?.codeContent}</span>
                </p>

                <div style={{ height: 1, backgroundColor: 'black', margin: '8px 0' }} />

                {item?.orderProduct?.map((prod, i) => (
                    <div key={prod?.id} style={{ marginBottom: '1rem' }}>
                        <h3 className={cn(prod.type === 'sold' ? 'has-text-black' : 'has-text-danger')} style={{ fontWeight: 'bold', textDecoration: prod?.type === 'returned' && 'line-through' }}>
                            {i + 1}. {prod?.product?.name}
                        </h3>

                        <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                            <span>Цена:</span>
                            <span style={{ fontWeight: 500 }}>
                                {prod?.count} {getMeasurement(prod?.product?.measurement)} x {format(prod?.price)} {item?.currency === 'usd' ? 'USD' : 'Сум'} ={' '}
                                <b>
                                    {format(prod?.count * prod.price)} {item?.currency === 'usd' ? 'USD' : 'Сум'}
                                </b>
                            </span>
                        </p>
                    </div>
                ))}

                <div style={{ height: 1, backgroundColor: 'black', margin: '10px 0' }} />

                <p style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                    <span style={{ fontWeight: 'bold' }}>Итого:</span>
                    <b>
                        {format(item?.sumComing)} {item?.currency === 'usd' ? 'USD' : 'Сум'}
                    </b>
                </p>
            </main>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem', padding: '20px', position: 'absolute', bottom: 0, left: 0, background: 'white', width: '100%', borderTop: '1px solid whitesmoke' }}>
                <Button onClick={onCancel} text="Отмена" className="is-outlined" />
                <Link onClick={onCancel} to={`/archive/order/${item.id}`} className="button is-danger is-block">
                    Возврат
                </Link>
            </div>
        </>
    )
}
