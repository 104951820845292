import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

import { useModal } from '../hooks/modal'
import Layout from '../components/Layout'
import { format } from '../utils/number'
import { useMessage } from '../hooks/message'
import Button from '../components/common/Button'
import NewTable from '../components/common/NewTable'
import { useQueryParams } from '../hooks/queryString'
import SummaryCard from '../components/common/SummaryCard'
import { useLoad, usePostRequest } from '../hooks/request'
import SupplierItemItem from '../components/SupplierItemItem'
import SupplierItemCreate from '../components/SupplierItemCreate'
import { SUPPLIER_DETAIL, SUPPLIER_ITEM_LIST, UPLOAD_FILE_SUPPLIER_ITEM } from '../urls'
import { getPricedType } from '../utils/types'

pdfMake.vfs = pdfFonts.pdfMake?.vfs

export default function SupplierChecksDetail() {
    const { supplierId } = useParams()
    const params = useQueryParams()
    const classDetail = useLoad({ url: SUPPLIER_DETAIL.replace('{id}', supplierId) })
    const className = classDetail.response
    const supplierItems = useLoad({ url: SUPPLIER_ITEM_LIST, params: { supplier: supplierId, ...params } })

    const [showCreateModal, hideCreateModal] = useModal(
        <SupplierItemCreate
            groupName={className ? className.name : ''}
            supplierId={supplierId}
            onSuccess={() => {
                classDetail.request()
                supplierItems.request()
                hideCreateModal()
            }}
            onCancel={() => hideCreateModal()}
        />
    )

    const navigate = useHistory()
    const [showMessage] = useMessage()

    const { request: postExcel } = usePostRequest({ url: UPLOAD_FILE_SUPPLIER_ITEM.replace('{id}', supplierId) })
    const handleProductImport = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        const { success, error } = await postExcel({ data: formData })
        if (success) {
            showMessage('Файл успешно отправлен', 'is-success')
            navigate.push('?')
        }
        if (error) showMessage(error?.data?.detail ?? `Error: ${error?.status} ${error?.statusText}`, 'is-danger')
    }

    const formatNumber = (num) => new Intl.NumberFormat('ru-RU').format(num)

    const exportToPdf = () => {
        if (!supplierItems?.response?.results || supplierItems?.response?.results.length === 0) {
            showMessage('Eksport qilish uchun ma’lumotlar yo‘q!', 'is-danger')
            return
        }

        const results = supplierItems?.response?.results

        // Calculate totals
        const allComingSum = results?.filter((item) => item.payment === 'sum' && item.paymentType === 'coming')?.reduce((total, item) => total + (Number(item?.amount) || 0), 0)
        const allInComingSum = results?.filter((item) => item.payment === 'sum' && item.paymentType === 'consumption')?.reduce((total, item) => total + (Number(item?.amount) || 0), 0)
        const allComingUsd = results?.filter((item) => item.payment === 'usd' && item.paymentType === 'coming')?.reduce((total, item) => total + (Number(item?.amount) || 0), 0)
        const allInComingUsd = results?.filter((item) => item.payment === 'usd' && item.paymentType === 'consumption')?.reduce((total, item) => total + (Number(item?.amount) || 0), 0)

        const content = results.map((item, index) => {
            const currency = item?.payment === 'sum' ? 'Сум' : 'USD'
            const amount = formatNumber(item?.amount || 0)
            const formattedDate = new Date(item.date).toLocaleDateString('ru-RU')

            const coming = item?.paymentType === 'coming' ? amount : '0'
            const incoming = item?.paymentType === 'consumption' ? amount : '0'

            const orderTable = {
                table: {
                    headerRows: 1,
                    widths: [30, '*', '*', 50, '*', '*'],
                    body: [
                        [
                            { text: '№', style: 'tableHeader' },
                            { text: 'Способ оплаты', style: 'tableHeader' },
                            { text: 'Дата', style: 'tableHeader' },
                            { text: 'Валюта', style: 'tableHeader' },
                            { text: 'Приход', style: 'tableHeader' },
                            { text: 'Расход', style: 'tableHeader' },
                        ],
                        [index + 1, item.reasonablyPriced ? getPricedType(item.reasonablyPriced) : null, formattedDate, currency, coming, incoming],
                    ],
                },
            }

            return [{ text: `Счет №${index + 1}`, style: 'sectionTitle', margin: [0, 10, 0, 5] }, { text: `Поставщик: ${item?.select_client?.name || 'Неизвестен'}`, style: 'subheader' }, { text: `Дата: ${formattedDate}`, style: 'subheader' }, orderTable, { text: ' ', margin: [0, 5] }]
        })

        const totalsSection = {
            table: {
                headerRows: 1,
                widths: [100, '*', '*', '*'],
                body: [
                    [
                        { text: 'Общий приход', style: 'tableHeader' },
                        { text: 'Общий расход', style: 'tableHeader' },
                        { text: 'Общая прибыль', style: 'tableHeader' },
                        { text: 'Валюта', style: 'tableHeader' },
                    ],
                    [formatNumber(allComingSum), formatNumber(allInComingSum), formatNumber(allComingSum - allInComingSum), 'Сум'],
                    [formatNumber(allComingUsd), formatNumber(allInComingUsd), formatNumber(allComingUsd - allInComingUsd), 'USD'],
                ],
            },
            margin: [0, 10, 0, 0],
        }

        const docDefinition = {
            content: [{ text: 'СЧЕТ-ФАКТУРЫ', style: 'header', alignment: 'center' }, totalsSection, ...content.flat(), { text: 'Общий итог:', style: 'sectionTitle', margin: [0, 10, 0, 5] }],
            styles: {
                header: { fontSize: 18, bold: true, marginBottom: 10 },
                sectionTitle: { fontSize: 14, bold: true },
                subheader: { fontSize: 12, marginBottom: 5 },
                tableHeader: { bold: true, fontSize: 12, color: 'black' },
            },
        }

        pdfMake.createPdf(docDefinition).download(`supplier_checks_${supplierId}.pdf`)
    }

    return (
        <Layout>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="is-flex">
                    <Link className="button is-white is-link is-outlined mr-3" to="/supplier">
                        <i className="icon ion-md-arrow-back is-size-4" />
                    </Link>
                    <div className="is-size-4">{className?.name}</div>
                </div>
                <div>
                    <Button onClick={exportToPdf} icon="cloud-download-outline" className="button is-link is-outlined ml-2 mr-2" text="PDF" />
                    <label htmlFor="123" style={{ position: 'relative' }}>
                        <Button icon="cloud-download-outline" className="button is-link is-outlined ml-2 mr-2" text="Импорт" />
                        <input id="123" onChange={handleProductImport} value="" type="file" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                    </label>
                    <Button icon="cloud-upload-outline" className="button is-link is-outlined ml-2 mr-2" text="Экспорт" />
                    <Button onClick={showCreateModal} text="Оплата" icon="cash-outline" className="is-link is-outlined" />
                </div>
            </div>

            <div style={{ display: 'flex', gap: 16, paddingTop: 10 }}>
                <SummaryCard label="Общая выгода USD:" value={`${format(className?.usdComingTotal - className?.usdConsumptionTotal || 0)} $`} />
                <SummaryCard label="Общая выгода Сум:" value={`${format(className?.sumComingTotal - className?.sumConsumptionTotal || 0)} Сум`} />
            </div>

            <br />

            <NewTable
                loading={supplierItems.loading}
                items={supplierItems.response ? supplierItems.response.results : []}
                columns={{ id: '№', name: 'Способ оплаты', phone: 'Примечание', phone_director: 'Дата', currency: 'Валюта', come: 'Приход', buttons: 'Расход', actions: '' }}
                totalCount={supplierItems.response ? supplierItems.response.count : 0}
                pageSize={15}
                renderItem={(item, index) => <SupplierItemItem classDetail={classDetail} groupName={className ? className.name : ''} index={index} key={item.id} item={item} supplierItems={supplierItems} />}
            />
        </Layout>
    )
}
