export const languages = {
    uz: 'O\'zbek',
    ru: 'Русский',
    en: 'English',
}

export const deliveryTypes = {
    delivery: 'Доставка',
    pickup: 'Самовывоз',
}
export const seasons = [
    { name: 'Зимний', value: 'winter' },
    { name: 'Летний', value: 'summer' },
]
export const classes = {
    admin: 'is-success',
    accountant: 'is-danger',
    marketing: 'is-primary',
    teacher: 'is-warning',
}

export function measurement(value = '') {
    const statuses = [
        { name: 'шт', value: 'pieces' },
        { name: 'литр', value: 'litres' },
        { name: 'м', value: 'metr' },
        { name: 'кг', value: 'kg' },
    ].filter((elem) => elem.value === value)[0]

    return statuses?.name
}

export function status(value = '') {
    const statuses = [
        { name: 'Долг', value: 'debt' },
        { name: 'Погашение задолженности', value: 'paid' },
    ].filter((elem) => elem.value === value)[0]

    return statuses?.name
}

export function currencyOptions(value = '') {
    const statuses = [
        { name: 'Сум', value: 'sum' },
        { name: 'Доллар', value: 'usd' },
    ].filter((elem) => elem.value === value)[0]

    return statuses?.name
}
export function position(name) {
    return languages[name]
}
export function currency(name) {
    return currencyOptions[name]
}

export function deliveryPosition(name) {
    return deliveryTypes[name]
}

export function positionClass(name) {
    return classes[name]
}
