import React, { useCallback, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import debounce from 'lodash/debounce'
import { useQueryParams } from '../hooks/queryString'

export default function Search({ onSearch, ...attributes }) {
    const queryParams = useQueryParams()
    const [value, setValue] = useState(queryParams.search || '')
    const inputRef = useRef(null)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus() // always set focus on render
        }
    }) // No dependencies, this will run after every render

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChange = useCallback(debounce(({ target }) => {
        onSearch(target.value)
    }, 200), [onSearch])

    return (
        <div style={{ width: '30%' }} className="">
            <div className="">
                <div className="field">
                    <div className="control has-icons-left is-expanded">
                        <input
                            ref={inputRef}
                            type="search"
                            value={value}
                            onChange={(event) => {
                                setValue(event.target.value)
                                onChange(event)
                            }}
                            className={cn('input')}
                            placeholder="Поиск..."
                            {...attributes}
                        />
                        <span className="icon is-small is-left">
                            <ion-icon name="search" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
