import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import queryString from 'querystring'
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout'
import Table from '../components/common/Table'
import { useLoad } from '../hooks/request'
import { DOCUMENTS_LIST } from '../urls'
import { useQueryParams } from '../hooks/queryString'
import Search from '../components/Search'
import Button from '../components/common/Button'
import DocumentsItem from '../components/DocumentsItem'

pdfMake.vfs = pdfFonts.pdfMake?.vfs

export default function Documents() {
    const [page, setPage] = useState(1)

    const params = useQueryParams()
    const suppliers = useLoad({ url: DOCUMENTS_LIST, params: { ...params, page } }, [page])
    const history = useHistory()

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ ...params, search })}`)
    }

    const exportToPdf = () => {
        const docDefinition = {
            content: [
                { text: 'СЧЕТ-ФАКТУРА', style: 'header' },
                { text: '№ 251424 от 30.10.2024 г.', style: 'subheader' },
                { columns: [{ text: 'Поставщик' }, { text: 'SKLAT', bold: true }] },
                { columns: [{ text: 'Адрес' }, { text: 'SHOFIRKON', bold: true }] },
                { text: 'Покупатель', style: 'sectionTitle' },
                { columns: [{ text: 'bobir aka pewku 3505 942473500' }, { text: '99963505' }] },
                { text: ' ' },
                { text: 'Товары', style: 'sectionTitle' },
                {
                    table: {
                        headerRows: 1,
                        widths: [30, '*', 50, 50, 60, 50],
                        body: [
                            [
                                { text: '№', style: 'tableHeader' },
                                { text: 'Наименование', style: 'tableHeader' },
                                { text: 'Ед. изм', style: 'tableHeader' },
                                { text: 'Кол-во', style: 'tableHeader' },
                                { text: 'Цена', style: 'tableHeader' },
                                { text: 'Сумма', style: 'tableHeader' },
                            ],
                            ['1', 'Эмаль PF-115 синий 0.7кг "UZBRAND"', 'банка', '12', '1.05', '12.60'],
                            ['2', 'Эмаль PF-115 зеленый 0.7кг "UZBRAND"', 'банка', '12', '1.05', '12.60'],
                            ['3', 'Эмаль PF-115 голубой 0.7кг "UZBRAND"', 'банка', '12', '1.05', '12.60'],
                            ['4', 'Эмаль PF-266 пол 2.5 кг "UZBRAND"', 'банка', '18', '3.33', '59.94'],
                        ],
                    },
                },
                { text: ' ' },
                { text: 'Задолженность: 106 623 880.8 сум и $543.73', style: 'sectionTitle' },
                { text: 'Последняя оплата: 26.10.2024 г. (1310.89 доллар, 2421000.00 сум)', style: 'footer' },
            ],
            styles: {
                header: { fontSize: 18, bold: true, alignment: 'center', margin: [0, 0, 0, 10] },
                subheader: { fontSize: 14, alignment: 'center', margin: [0, 0, 0, 20] },
                sectionTitle: { fontSize: 12, bold: true, margin: [0, 10, 0, 5] },
                tableHeader: { bold: true, fillColor: '#eeeeee' },
                footer: { fontSize: 10, alignment: 'left', margin: [0, 20, 0, 0] },
            },
        }

        pdfMake.createPdf(docDefinition).download('invoice.pdf')
    }

    return (
        <Layout>
            <div className="is-flex mb-3">
                <Search onSearch={onSearch} />

                <Button onClick={exportToPdf} className="button is-link is-outlined ml-2 mr-2" text="Экспорт" />

                <label style={{ position: 'relative' }}>
                    <Button className="button is-link is-outlined mr-2" text="Импорт" />
                    {/* eslint-disable-next-line max-len */}
                    <input
                        value=""
                        onChange={() => {}}
                        type="file"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: 0,
                        }}
                    />
                </label>

                <Button text="Добавить" className="button is-link is-outlined"
                    onClick={() => history.push('/documents-in/create')} />
            </div>

            <Table
                pagination
                activePage={page}
                onPageChange={setPage}
                loading={suppliers.loading}
                items={suppliers.response ? suppliers.response.results : []}
                columns={{ id: '', name: 'Поставщик\t', phone: '№ Документ', phone_director: 'Сумма\t', phone_accountant: 'Дата приема\t', buttons: '' }}
                totalCount={suppliers.response ? suppliers.response.count : 0}
                s
                renderItem={(item, index) => <DocumentsItem index={index} key={item.id} item={item} suppliers={suppliers} />}
            />
        </Layout>
    )
}
