import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { useQueryParams } from '../../hooks/queryString'

import Loader from './Loader'
import Pagination from './Pagination'

export default function Table({ loading = false, emptyMessage = 'Пусто', showEmptyMessage = true, totalCount = 0, pageSize = 15, items, columns, pagination = true, renderItem, className = 'table is-striped is-fullwidth is-bordered', onPageChange, activePage, emptyMessageColor = null }) {
    const params = useQueryParams()

    if (loading) {
        return (
            <div className={css(styles.space)}>
                <Loader large center />
            </div>
        )
    }

    if (isEmpty(items) && showEmptyMessage) {
        const style = cn(emptyMessageColor || 'has-text-grey', 'is-size-5 has-text-centered', css(styles.space))
        return <div className={style}>{emptyMessage}</div>
    }

    return (
        <div style={{ overflowX: 'auto' }}>
            <table className={className}>
                <tbody>
                    {columns ? (
                        <tr>
                            {Object.entries(columns).map(([key, value]) => (
                                <th key={key}>
                                    <p style={{ width: 'max-content' }}>{value}</p>
                                </th>
                            ))}
                        </tr>
                    ) : null}

                    {items.map(renderItem)}
                </tbody>
            </table>

            {pagination ? (
                <Pagination
                    currentPage={params.page || activePage}
                    totalCount={totalCount}
                    pageSize={pageSize}
                    onPageChange={(page) => {
                        if (typeof onPageChange === 'function') onPageChange(page)
                    }}
                />
            ) : null}
        </div>
    )
}

const styles = StyleSheet.create({ space: { marginTop: '2rem' } })
