import cn from 'classnames'
import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import Button from './common/Button'
import { format } from '../utils/number'
import { getDateTime } from '../utils/date'
import { getPaymentMethods, getStatusTypes } from '../utils/types'
import { CustomCheckPrint } from './customs/check-print'

export default function ArchiveItem({ item, index }) {
    const resultArchive = item.orderProduct.reduce((totalCost, currentValue) => {
        const count = parseInt(currentValue?.count, 10) || 0
        const price = parseInt(currentValue?.price, 10) || 0

        return totalCost + count * price
    }, 0)

    const componentRef = useRef()

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    console.log(item, 'nimabdan')

    return (
        <>
            <td>{index + 1}</td>
            <td className={cn(item?.status === 'successfully' ? 'has-text-black' : 'has-text-danger')}>{getStatusTypes(item?.status)}</td>
            <td>{item?.codeContent}</td>
            <td>{item.client.name}</td>
            <td className="">{item.client.phoneNumber}</td>
            <td className="">
                {format(resultArchive)} {item?.currency === 'usd' ? 'USD' : 'Сум'}
            </td>
            <td className="">{getDateTime(item.createdAt)}</td>
            <td>{item.paymentMethod ? getPaymentMethods(item.paymentMethod) : ''}</td>
            <td>
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            handlePrint()
                        }}
                        className="button is-link mr-2 is-small is-outlined"
                        icon="print"
                    />
                    <div style={{ display: 'none' }}>
                        <CustomCheckPrint paidAmount={item.sumComing} lend={item.amount - item.sumComing} result={item.amount} item={item} ref={componentRef} />
                    </div>
                </div>
            </td>
        </>
    )
}
