import React from 'react'
import { Form, Formik } from 'formik'
import Select from 'react-select'
import Button from '../common/Button'
import { required } from '../../utils/validators'
import Input from '../common/Input'
import { useLoad } from '../../hooks/request'
import { CLIENT_LIST } from '../../urls'

export default function PaymentForm({ onCancel, onSubmit, initialValues, loading, setClientId }) {
    const clients = useLoad({ url: CLIENT_LIST, params: { type: 'active' } }, [])

    const results = clients.response ? clients.response.results : []

    // Map clients to options for react-select
    const clientOptions = results.map((client) => ({
        value: client.id,
        label: client.name,
    }))

    return (
        <Formik onSubmit={onSubmit} initialValues={{ client: '', name: '', description: '', amount: '', termDate: '', ...initialValues }}>
            <Form>
                <Select
                    options={clientOptions}
                    onChange={(option) => setClientId(option.value)}
                    name="client"
                    id="client"
                    placeholder="Выберите клиента"
                    styles={{ container: (provided) => ({ ...provided, marginBottom: '5px' }) }}
                />

                <Input name="amount" type="number" label="Цена" validate={required} />
                <Input name="description" component="textarea" label="Описание" />
                <div className="is-pulled-right">
                    <Button onClick={onCancel} icon="close-outline" text="Закрыть" className="is-danger" />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сохранить" icon="add-outline" type="submit" className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
