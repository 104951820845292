import moment from 'moment'
import filter from 'lodash/filter'

export const DATE_FORMAT = 'DD-MM-YYYY'
export const DATE_AUTO_FORMAT = 'YYYY-MM-DD'
export const DATETIME_FORMAT = 'HH:mm DD.MM.YYYY'

export const DAYS = [
    { key: 'monday', title: 'Понедельник', shortTitle: 'Пн' },
    { key: 'tuesday', title: 'Вторник', shortTitle: 'Вт' },
    { key: 'wednesday', title: 'Среда', shortTitle: 'Ср' },
    { key: 'thursday', title: 'Четверг', shortTitle: 'Чт' },
    { key: 'friday', title: 'Пятница', shortTitle: 'Пт' },
    { key: 'saturday', title: 'Суббота', shortTitle: 'Сб' },
    { key: 'sunday', title: 'Воскресенье', shortTitle: 'Вс' },
]

export function day(dayKey) {
    return filter(DAYS, { key: dayKey })[0]
}

export function getMonth(date = new Date(), withYear = false) {
    const format = withYear ? 'MMMM YYYY' : 'MMMM'
    const month = moment(date).format(format)
    return (month)[0].toUpperCase() + (month).slice(1)
}

export function getDay(date = new Date(), withYear = false) {
    const format = withYear ? 'DD MMMM YYYY' : 'DD'
    const days = moment(date).format(format)
    return (days)[0].toUpperCase() + (days).slice(1)
}

export function getDateTime(date = new Date()) {
    return moment(date).format(DATETIME_FORMAT)
}

export function timeAgo(date) {
    const dates = moment(date)
    const diffInSeconds = Math.floor((new Date() - dates) / 1000)

    let interval = Math.floor(diffInSeconds / 31536000)
    if (interval >= 1) {
        return `${interval} год${interval > 1 ? 'а' : ''} назад`
    }

    interval = Math.floor(diffInSeconds / 2592000)
    if (interval >= 1) {
        return `${interval} месяц${interval > 1 ? 'а' : ''} назад`
    }

    interval = Math.floor(diffInSeconds / 86400)
    if (interval >= 1) {
        return `${interval} день${interval > 1 ? 'я' : ''} назад`
    }

    interval = Math.floor(diffInSeconds / 3600)
    if (interval >= 1) {
        return `${interval} час${interval > 1 ? 'а' : ''} назад`
    }

    interval = Math.floor(diffInSeconds / 60)
    if (interval >= 1) {
        return `${interval} минут${interval > 1 ? 'ы' : ''} назад`
    }

    return 'только что'
}

export function getDate(date = new Date()) {
    return moment(date).format(DATE_FORMAT)
}

export function getDateOtherFormat(date = new Date()) {
    return moment(date).format(DATE_AUTO_FORMAT)
}

export const status = [
    { name: 'Новый', value: 'new' },
    { name: 'Принят', value: 'accepted' },
    { name: 'Доставлен', value: 'delivered' },
    { name: 'Отменён', value: 'canceled' },
]

export function getStatus(value) {
    return status.find((elem) => elem.value === value) || {}
}
