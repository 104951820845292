import { isEmpty } from 'lodash'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React, { Fragment, useEffect, useState } from 'react'
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom'

import { signOut } from '../../utils/auth'
import Button from '../common/Button'
import { useGetRequest, usePostRequest } from '../../hooks/request'
import { ALL_SEND_MESSAGE, CLIENT_EXPORT, domain, PRODUCT_EXPORT } from '../../urls'
import { useMessage } from '../../hooks/message'
import DateFilter from '../common/DateFilter'
import SearchFilter from '../common/SearchFIlter'
// import { useQueryParams } from '../../hooks/queryString'

export default function Layout({
    children,
    padding = true,
    className,
    showCreateModal,
    showCreateCheck,
    onSend: onSendSelect,
}) {
    // const params = useQueryParams()
    const history = useHistory()
    const { path } = useRouteMatch()
    const [menu, setMenu] = useState(false)
    const user = JSON.parse(localStorage.user)
    async function logOut() {
        if (global.confirm('Вы действительно хотите выйти из системы ?')) {
            await signOut(history)
        }
    }

    const sendMessage = useGetRequest({ url: ALL_SEND_MESSAGE })
    const [showMessage] = useMessage()

    async function onSend() {
        const { success } = await sendMessage.request()
        if (success) {
            showMessage('Сизнинг sms хабарингиз  муваффақиятли юборилди', 'is-success')
        }
    }

    const excel = useGetRequest({ url: CLIENT_EXPORT })
    const excelItem = excel.response ? excel.response.fileUrl || excel.response.file : null

    useEffect(() => {
        if (excelItem && !isEmpty(excelItem)) {
            const a = document.createElement('a')
            a.href = `${domain}${excelItem}`
            a.setAttribute('file', 'response')
            a.click()
        }
    }, [excelItem])
    const excelProduct = useGetRequest({ url: PRODUCT_EXPORT })
    const excelItemProduct = excelProduct.response ? excelProduct.response.fileUrl || excelProduct.response.file : null

    useEffect(() => {
        if (excelItemProduct && !isEmpty(excelItemProduct)) {
            const a = document.createElement('a')
            a.href = `${domain}${excelItemProduct}`
            a.setAttribute('file', 'response')
            a.click()
        }
    }, [excelItemProduct])

    const { request: postExcel } = usePostRequest({ url: '' })
    const handleProductImport = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        const { success, error } = await postExcel({ data: formData })
        if (success) {
            showMessage('Файл успешно отправлен', 'is-success')
            history.push('?')
        }
        if (error) {
            showMessage(error?.data?.detail ?? `Error: ${error?.status} ${error?.statusText}`, 'is-danger')
        }
    }

    return (
        <div className={cn(css(styles.parent, path.startsWith('/settings') ? styles.blueBgSidebar : ''), className)}>
            <div>
                {user.admin ? (
                    <Fragment>
                        <div className={cn(css(styles.sidebar, menu ? styles.desktopSidebar : styles.mobileSidebar))}>
                            <ul className={css(styles.sideMenu)}>

                                <NavLink to="/categories" exact>
                                    <li className={cn(
                                        'columns is-mobile is-gapless',
                                        css(styles.link),
                                        {
                                            [css(styles.active)]:
                                            path.startsWith('/categories') || path.startsWith('/category'),
                                        },
                                    )}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="list-outline" />
                                        </span>

                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Категории
                                        </span>
                                    </li>
                                </NavLink>

                                <NavLink to="/products" exact>
                                    <li className={cn(
                                        'columns is-mobile is-gapless',
                                        css(styles.link),
                                        { [css(styles.active)]: path.startsWith('/products') },
                                    )}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="fast-food-outline" />
                                        </span>
                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Товары
                                        </span>
                                    </li>
                                </NavLink>

                                <NavLink to="/territory" exact>
                                    <li className={cn('columns is-mobile is-gapless', css(styles.link),
                                        { [css(styles.active)]: path.startsWith('/territory') })}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="globe-outline" />
                                        </span>

                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Территории
                                        </span>
                                    </li>
                                </NavLink>

                                <NavLink to="/brands" exact>
                                    <li className={cn(
                                        'columns is-mobile is-gapless',
                                        css(styles.link),
                                        { [css(styles.active)]: path.startsWith('/brands') },
                                    )}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="business-outline" />
                                        </span>

                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Фирмы
                                        </span>
                                    </li>
                                </NavLink>

                                <li
                                    className={cn('columns is-mobile is-gapless is-clickable', css(styles.link))}
                                    onClick={logOut}
                                >
                                    <span className={cn('column is-narrow', css(styles.icon))}>
                                        <ion-icon name="log-out-outline" />
                                    </span>

                                    <span className={cn('column is-narrow', css(styles.text))}>
                                        Выйти
                                    </span>
                                </li>

                            </ul>
                        </div>

                        <div className={cn(css(styles.container))}>
                            <div className={cn(css(styles.content), { [css(styles.padding)]: padding })}>
                                <span onClick={() => setMenu(true)} className={css(styles.showerIcon)}>
                                    <ion-icon size="large" name="menu-outline" />
                                </span>
                                {children}
                            </div>
                        </div>

                        <div className={css(menu ? styles.background : null)} onClick={() => setMenu(false)} />
                    </Fragment>
                ) : (
                    <Fragment>
                        {/* eslint-disable-next-line max-len */}
                        <div
                            className={cn(css(styles.marketSidebar, menu
                                ? styles.marketDesktopSidebar : styles.marketMobileSidebar), 'market_sidebar has-background-primary')}>
                            <ul className={css(styles.sideMenu)}>

                                <NavLink to="/products" exact>
                                    <li className={cn(
                                        'columns is-mobile is-gapless',
                                        css(styles.link),
                                        {
                                            [css(styles.active)]:
                                            path.startsWith('/products') || path.startsWith('/products'),
                                        },
                                    )}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="cash-outline" />
                                        </span>

                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Кассовый аппарат
                                        </span>
                                    </li>
                                </NavLink>

                                <NavLink to="/borrow?status=active" exact>
                                    <li className={cn(
                                        'columns is-mobile is-gapless',
                                        css(styles.link),
                                        {
                                            [css(styles.active)]:
                                            path.startsWith('/borrow') || path.startsWith('/borrow'),
                                        },
                                    )}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="wallet" />
                                        </span>

                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Долги
                                        </span>
                                    </li>
                                </NavLink>

                                <NavLink to="/categories" exact>
                                    <li className={cn(
                                        'columns is-mobile is-gapless',
                                        css(styles.link),
                                        {
                                            [css(styles.active)]:
                                            path.startsWith('/categories') || path.startsWith('/category'),
                                        },
                                    )}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="list-outline" />
                                        </span>

                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Категории
                                        </span>
                                    </li>
                                </NavLink>

                                <NavLink to="/home?category=" exact>
                                    <li className={cn('columns is-mobile is-gapless', css(styles.link),
                                        { [css(styles.active)]: path.startsWith('/home') })}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="fast-food-outline" />
                                        </span>

                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Товары
                                        </span>
                                    </li>
                                </NavLink>

                                <NavLink to="/archive/orders" exact>
                                    <li className={cn('columns is-mobile is-gapless', css(styles.link),
                                        { [css(styles.active)]: path.startsWith('/archive') })}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="cart-outline" />
                                        </span>
                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Корзина
                                        </span>
                                    </li>
                                </NavLink>

                                <NavLink to="/bonus" exact>
                                    <li className={cn('columns is-mobile is-gapless', css(styles.link),
                                        { [css(styles.active)]: path.startsWith('/bonus') })}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="server-outline" />
                                        </span>
                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Бонус
                                        </span>
                                    </li>
                                </NavLink>

                                <NavLink to="/settings/user" exact
                                    className={cn(path.startsWith('/settings') && 'active')}
                                >
                                    <li className={cn('columns is-mobile is-gapless', css(styles.link),
                                        { [css(styles.active)]: path.startsWith('/settings') })}>
                                        <span className={cn('column is-narrow', css(styles.icon))}>
                                            <ion-icon name="settings-outline" />
                                        </span>
                                        <span className={cn('column is-narrow', css(styles.text))}>
                                            Настройки
                                        </span>
                                    </li>
                                </NavLink>

                                <li
                                    className={cn('columns is-mobile is-gapless is-clickable', css(styles.link))}
                                    onClick={logOut}
                                >
                                    <span className={cn('column is-narrow', css(styles.icon))}>
                                        <ion-icon name="log-out-outline" />
                                    </span>

                                    <span className={cn('column is-narrow', css(styles.text))}>
                                        Выйти
                                    </span>
                                </li>
                            </ul>
                        </div>

                        <div className={cn(css(styles.marketContainer))}>
                            <div className={cn(className, css(styles.content), { [css(styles.padding)]: padding })}>
                                <span className={css(styles.marketShowerIcon)}>

                                    <div
                                        onClick={() => history.push('/cart')}
                                        className={cn(css(styles.cart), 'is-clickable')}
                                    />
                                </span>

                                {/* eslint-disable-next-line max-len */}
                                <main className="mb-4 filter_bar">
                                    {/* eslint-disable-next-line max-len */}
                                    <div className="first-child" style={{ display: 'flex', gap: '1rem', width: '100%' }}>
                                        <div className="search_bar" style={{ width: '100%' }}>
                                            <i>
                                                <ion-icon onClick={() => setMenu(true)} size="large" name="menu-outline" />
                                            </i>

                                            {!path.startsWith('/settings') && <SearchFilter />}
                                        </div>

                                        {path.startsWith('/home') ? (
                                            <div
                                                className="date_filter"
                                                style={{ display: 'flex', gap: '1rem' }}
                                            >
                                                <DateFilter name="startDate" />
                                                <DateFilter name="endDate" />
                                            </div>
                                        ) : null}

                                        {path.startsWith('/borrow') ? (
                                            <DateFilter name="date" />
                                        ) : null}

                                        {path.startsWith('/archive/orders') ? (
                                            <DateFilter name="date" />
                                        ) : null}

                                        {path.startsWith('/archive/orders') ? (
                                            <div
                                                style={{ display: 'flex', gap: '1rem' }}
                                            >
                                                <DateFilter name="startDate" />
                                                <DateFilter name="endDate" />
                                            </div>
                                        ) : null}
                                    </div>

                                    {/* eslint-disable-next-line max-len */}
                                    <div className="second-child" style={{ display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'flex-end' }}>
                                        {path.startsWith('/home') ? (
                                            <label style={{ position: 'relative' }}>
                                                <Button
                                                    icon="cloud-download-outline"
                                                    className=" button is-info"
                                                    text="Импорт" />
                                                {/* eslint-disable-next-line max-len */}
                                                <input onChange={handleProductImport} value="" type="file" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 }} />
                                            </label>
                                        ) : null}
                                        {path.startsWith('/products') || path.startsWith('/borrow')
                                        || path.startsWith('/categories') || path.startsWith('/home')
                                        || path.startsWith('/category')
                                            ? (
                                                <Button
                                                    text="Добавить"
                                                    icon="add-outline"
                                                    className="button is-info "
                                                    onClick={showCreateModal} />
                                            ) : null}
                                        {path.startsWith('/borrow') ? (
                                            <Button
                                                text="Выберите"
                                                icon="send-outline"
                                                className="button is-info"
                                                onClick={onSendSelect} />
                                        ) : null}
                                        {path.startsWith('/products') ? (
                                            <Button
                                                onClick={showCreateCheck}
                                                icon="add-outline"
                                                className=" button is-info"
                                                text=" Янги чек қўшиш" />
                                        ) : null}
                                        {path.startsWith('/borrow') ? (
                                            <Button
                                                loading={sendMessage.loading}
                                                onClick={onSend}
                                                icon="paper-plane-outline"
                                                className=" button is-info"
                                                text="Всем SMS" />
                                        ) : null}
                                        {path.startsWith('/borrow') ? (
                                            <Button
                                                onClick={excel.request}
                                                icon="cloud-upload-outline"
                                                className=" button is-info"
                                                text="Ехcел" />
                                        ) : null}
                                        {path.startsWith('/home') ? (
                                            <Button
                                                onClick={excelProduct.request}
                                                icon="cloud-upload-outline"
                                                className=" button is-info"
                                                text="Экспорт" />
                                        ) : null}
                                        {path.startsWith('/cart') ? (
                                            <Button
                                                onClick={() => history.push('products')}
                                                icon="archive-outline"
                                                className="button is-info"
                                            />
                                        ) : null}
                                        {path.startsWith('/home') ? (
                                            <Button
                                                onClick={() => history.push('categories')}
                                                icon="arrow-back"
                                                className="button is-info"
                                            />
                                        ) : null}

                                    </div>
                                </main>

                                {children}
                            </div>
                        </div>

                        <div className={css(menu ? styles.marketBackground : null)} onClick={() => setMenu(false)} />
                    </Fragment>
                )}
            </div>

        </div>
    )
}

const styles = StyleSheet.create({
    parent: {
        ':nth-child(1n) .filter_bar': {
            display: 'flex',
            columnGap: '2rem',
            rowGap: '1rem',
            width: '100%',
            '@media (max-width: 1280px)': {
                flexDirection: 'column',
                ':nth-child(1n) .second-child': {
                    flexWrap: 'wrap',
                },
            },
            ':nth-child(1n) .first-child': {
                '@media (max-width: 769px)': {
                    flexDirection: 'column',
                    ':nth-child(1n) .date_filter': {
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(11rem, auto))',
                    },
                },
                ':nth-child(1n) .search_bar': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                },
            },
        },
    },
    blueBgSidebar: {
        ':nth-child(1n) .market_sidebar': {
            background: 'rgb(31, 38, 104)',
        },
        ':nth-child(1n) li': {
            color: 'white',
            opacity: '0.6',
        },
        ':nth-child(1n) ul .active li': {
            opacity: 1,
        },
    },
    content: {
        minHeight: '100vh',
        background: '#f2f6f7',
    },
    marketName: {
        position: 'absolute',
        marginTop: '2.8rem',
        marginLeft: '2rem',
        fontWeight: '400',
    },
    desktopSidebar: {
        '@media (max-width: 769px)': {
            transform: 'translateX(0)',
            transition: 'all 300ms',
        },
    },
    mobileSidebar: {
        '@media (max-width: 769px)': {
            transform: 'translateX(-15rem)',
            transition: 'all 300ms',
        },
    },
    showerIcon: {
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    sidebar: {
        minHeight: '100%',
        position: 'fixed',
        width: '14.1rem',
        margin: '0',
        zIndex: 6,
        paddingTop: '10%',
        boxShadow: '0 0.5em 1em rgba(10, 10, 10, 0.1)',
        '::-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-height: 1024px)': {
            height: 'calc(100vh - 100%)',
            background: 'white',
            overflowX: 'scroll',
        },
    },
    sideMenu: {
        marginTop: '3.3rem',
        marginLeft: '0rem',
    },
    padding: {
        padding: '1.8rem',
    },
    link: {
        color: 'rgba(0, 0, 0, 0.5)',
        fontWeight: '400',
        marginBottom: '1rem',
    },
    icon: {
        margin: '0rem 1rem 0rem 2rem',
    },
    container: {
        margin: '0 0 0 14rem',
        '@media (max-width: 769px)': {
            margin: '0',
        },
    },
    logo: {
        marginTop: '6rem',
        marginLeft: '2.1rem',
        width: '8rem',
    },
    active: {
        fontWeight: '600',
        color: '#53a4c1',
        boxShadow: 'inset 4px 0px #53a4c1',
    },
    textButton: {
        background: 'gray',
    },
    hiderIcon: {
        transform: 'translate(9.6rem, -15rem)',
        color: 'white',
        '@media (min-width: 769px)': {
            display: 'none',
        },
    },
    text: {
        marginTop: '3px',
    },
    background: {
        '@media (max-width: 769px)': {
            bottom: 0,
            left: 0,
            position: 'fixed',
            right: 0,
            top: 0,
            background: 'RGBA(0, 0, 0, .5)',
            zIndex: 4,
        },
    },
    marketDesktopSidebar: {
        transform: 'translateX(0)',
        transition: 'all 300ms',
    },
    marketMobileSidebar: {
        transform: 'translateX(-14rem)',
        transition: 'all 300ms',
    },
    marketShowerIcon: {
        display: 'flex',
        flexDirection: 'row',

    },
    marketSidebar: {
        minHeight: '100%',
        position: 'fixed',
        width: '14.1rem',
        margin: '0',
        zIndex: '5',
        paddingTop: '10%',
        boxShadow: '0 0.5em 1em rgba(10, 10, 10, 0.1)',
        '::-webkit-scrollbar': {
            display: 'none',
        },
        '@media (max-height: 1500px)': {
            height: 'calc(100vh - 100%)',
            background: 'white',
            overflowX: 'scroll',
        },
    },
    marketContainer: {
        margin: '0',
    },
    marketHiderIcon: {
        transform: 'translate(9.6rem, -15rem)',
        color: 'white',
    },
    marketBackground: {
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        background: 'RGBA(0, 0, 0, .5)',
        zIndex: 4,
    },
    cart: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto',
        fontSize: '32px',
    },
    circle: {
        border: '2px solid white',
        borderRadius: '50%',
        color: 'white',
        fontSize: '14px',
        minWidth: '30px',
        height: '28px',
        textAlign: 'center',
        verticalAlign: 'middle',
    },
    circleOrders: {
        border: '2px solid white',
        borderRadius: '50%',
        color: 'white',
        fontSize: '12px',
        paddingTop: 1,
        marginTop: 4,
        marginLeft: 8,
        width: '25px',
        height: '24px',
        textAlign: 'center',
        verticalAlign: 'middle',
    },
})
