import React from 'react'
import { usePostRequest } from '../hooks/request'
import { SUPPLIER_LIST } from '../urls'
import { useMessage } from '../hooks/message'
import SupplierForm from './SupplierForm'

export default function SupplierCreate({ suppliers, onCancel, userList, territory }) {
    const market = usePostRequest({ url: SUPPLIER_LIST })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await market.request({ data })

        if (success) {
            showMessage('Успешно сохранен', 'is-success')
            suppliers.request()
            onCancel()
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Добавить Поставщики</b></h1>

            <SupplierForm
                onCancel={onCancel}
                userList={userList}
                onSubmit={onSubmit}
                territory={territory} />
        </div>
    )
}
