import { StyleSheet, css } from 'aphrodite'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import queryString from 'querystring'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import CheckCreate from '../../components/CheckCreate'
import Layout from '../../components/Layout'
import ProductCreate from '../../components/ProductCreate'
import Search from '../../components/Search'
import Button from '../../components/common/Button'
import Table from '../../components/common/Table'
import { useModal } from '../../hooks/modal'
import { useQueryParams } from '../../hooks/queryString'
import { useGetRequest, useLoad } from '../../hooks/request'
import empty from '../../static/empty-shop.png'
import { PRODUCT_EXPORT, domain } from '../../urls'
import PriceTagsColumn from './Column'
import codeImage from '../../static/code.avif'

export default function PriceTags() {
    const params = useQueryParams()
    // const componentRef = useRef()
    const [page, setPage] = useState(1)
    const [selectedItem, setSelectedItem] = useState(null)
    const products = {
        response: {
            results: [
                { id: 1, product_name: 'ВАНА БАРДИЁОР ОК', quantity: '1', code: '946712', price: '44 000.00', discount: '0', action: '17.06.2024 12:45' },
                { id: 2, product_name: 'ТРАЙНИК (100 Х 50) КАНАЛ', quantity: '1', code: '946713', price: '49 530.00', discount: '0', action: '17.06.2024 12:45' },
                { id: 3, product_name: 'ТРАЙНИК (100 Х 50) КАНАЛ', quantity: '1', code: '946713', price: '49 530.00', discount: '0', action: '17.06.2024 12:45' },
                { id: 4, product_name: 'ТРАЙНИК (100 Х 50) КАНАЛ', quantity: '1', code: '946713', price: '49 530.00', discount: '0', action: '17.06.2024 12:45' },
                { id: 5, product_name: 'ТРАЙНИК (100 Х 50) КАНАЛ', quantity: '1', code: '946713', price: '49 530.00', discount: '0', action: '17.06.2024 12:45' },
            ],
        },
    }
    const productList = products.response ? products.response.results : []
    const check = useLoad({ url: '/main/order' })

    const [showCreateModal, setShowCreateModal] = useModal(<ProductCreate reload={products} onCancel={() => setShowCreateModal()} />, styles.modal)
    const [showCreateCheck, hideCreateCheck] = useModal(<CheckCreate reload={check} onCancel={() => hideCreateCheck()} />)

    const navigate = useHistory()

    const excelProduct = useGetRequest({ url: PRODUCT_EXPORT })
    const excelItemProduct = excelProduct.response ? excelProduct.response.fileUrl || excelProduct.response.file : null

    useEffect(() => {
        if (excelItemProduct && !isEmpty(excelItemProduct)) {
            const a = document.createElement('a')
            a.href = `${domain}${excelItemProduct}`
            a.setAttribute('file', 'response')
            a.click()
        }
    }, [excelItemProduct])

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        navigate.push(`?${queryString.stringify({ ...params, search })}`)
    }

    return (
        <Layout showCreateModal={showCreateModal} showCreateCheck={showCreateCheck}>
            <h1 className="is-size-4 has-text-weight-medium" style={{ marginBottom: '20px' }}>
                Инвентаризация
            </h1>
            <div className="is-flex mb-3">
                <Search onSearch={onSearch} style={{ width: '40%' }} />

                <Button text="Добавить" icon="add-outline" className="button is-link is-outlined ml-2" onClick={showCreateModal} />
            </div>

            <div className="mt-4">
                {products.response && products.response.count === 0 ? (
                    <div className="is-flex is-justify-content-center is-align-items-center hei">
                        <img alt="empty" src={empty} className={cn(css(styles.emptyImg), 'is-horizontal-center')} />
                    </div>
                ) : (
                    <Table
                        loading={products.loading}
                        items={productList}
                        columns={{ id: '', product_name: 'Наименование товара', quantity: 'Количество', code: 'Штрих-код', price: 'Цена', discount: 'Скидка', action: 'Действие' }}
                        totalCount={products.response ? products.response.count : 0}
                        pageSize={10}
                        activePage={page}
                        onPageChange={setPage}
                        renderItem={(item, index) => <PriceTagsColumn index={index} products={products} check={check} key={item.id} item={item} />}
                    />
                )}
            </div>

            {/* <div style={{ marginTop: 20 }}> */}
            {/*     <ReactToPrint trigger={() => <Button text="Print this out!" icon="add-outline" className="button is-link is-outlined ml-2" />} content={() => componentRef.current} /> */}

            {/*     <CheckComponent ref={componentRef} /> */}
            {/* </div> */}

            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 20, marginLeft: 20, gap: 20 }}>
                {productList.map((product) => (
                    <div key={product.id} onClick={() => setSelectedItem(product.id)} className={cn(css(styles.card), { [css(styles.selected)]: selectedItem === product.id })}>
                        <p style={{ fontSize: 12, alignSelf: 'flex-end', margin: 0, padding: 0 }}>{product.action}</p>
                        <h2 style={{ fontSize: 16, width: '80%', margin: 0, padding: 0 }}>{product.product_name}</h2>
                        <p style={{ fontSize: 24, fontWeight: 'bold', margin: 0, padding: 0 }}>{product.price} So`m</p>
                        <img src={codeImage} alt="Barcode" style={{ width: '150px', height: '50px', margin: 0, padding: 0 }} />
                        <p style={{ fontSize: 15, fontWeight: 'bold', margin: 0, padding: 0 }}>{product.code}</p>
                        <p style={{ fontSize: 12, fontWeight: 'bold', margin: 0, padding: 0 }}>Pos market</p>
                    </div>
                ))}
            </div>
        </Layout>
    )
}

const styles = StyleSheet.create({
    space: { marginTop: '2rem' },
    emptyImg: { width: '330px', height: '300px' },
    loader: { marginTop: '2rem' },
    modal: { width: 800 },
    checkmark: { marginRight: '3px' },
    card: {
        textAlign: 'center',
        border: '1px solid gray',
        padding: 2,
        display: 'flex',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: 240,
        height: 200,
        margin: 0,
        backgroundColor: 'white',
        cursor: 'pointer',
    },
    selected: { borderColor: 'blue', borderWidth: 2 },
})
